import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { size } from "../../utils/breakpoints";
import { theme } from "../../utils/theme";
import { MutatingDots } from "react-loader-spinner";
// AXIOS
import axios from "axios";
import Card from "../../components/Card/Card";
import ThermostatCard from "../../components/Card/CardTypes/ThermostatCard";
import RelayCard from "../../components/Card/CardTypes/RelayCard";
//COMPONENTS
import SerchFilterPanel from "../../layout/Navbar/SerchFilterPanel";
import { fetchDevices } from "../../services/ApiService";
import RouteLayout from "../../layout/RouteLayout";
import { DeviceTypeEnum } from "../../utils/Enums/DeviceTypeEnum";

import { useLanguage } from "../../services/LanguageContext";
// import SignalRService from "../../services/SignalRService";
import { useSignalR } from "../../services/SignalRProvider";

import { Loader100 } from "../../utils/utilsstyles";
import Device from "../../services/Device";
import { Ikonka } from "../../components/Icons/Icon";

const Empty = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 500px;
  //background-color: red;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;

  svg {
    height: 100px;
    margin-bottom: 1rem;
    fill: ${theme.color.base1};
  }

  p {
    font-size: 18px;
    line-height: 1.3;
  }
`;

const RoomName = styled.div`
  background-color: transparent;
  color: ${theme.color.base1};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: -1rem;
  grid-column: 1 / span 3;
  @media ${size.xl} {
    grid-column: 1 / span 2;
  }

  @media ${size.l} {
    grid-column: 1 / span 2;
  }

  @media ${size.md} {
    grid-column: 1 / span 2;
  }

  @media ${size.sm} {
    grid-column: 1 / span 1;
  }

  @media ${size.xs} {
    grid-column: 1 / span 1;
  }
`;

const Elements = styled.div`
  margin-top: 250px;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2rem;
  column-gap: 2rem;
  position: relative;

  @media ${size.xl} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${size.l} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${size.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${size.sm} {
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${size.xs} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const DataComponent = ({ title }) => {
  const [data, setData] = useState([]);
  const [deviceFilter, setDeviceFilter] = useState({
    home: null,
    rooms: [],
    name: "",
  });
  const [rooms, setRooms] = useState([]);
  const [homes, setHomes] = useState([]);
  const [filterHome, setFilterHome] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const { translate } = useLanguage();
  const signalRService = useSignalR();

  // Ref to track if component has already fetched data
  const hasFetchedData = useRef(false);
  const isFetching = useRef(false); // Ref to track if a fetch is ongoing

  useEffect(() => {
    console.log("Ładowanie domu");
    axios.defaults.withCredentials = true;

    if (!hasFetchedData.current) {
      hasFetchedData.current = true; // Mark that the initial fetch has occurred
      fetchData();
    }

    const handleUpdateDeviceStatus = (device) => {
      setMessages((prevMessages) => [...prevMessages, `${device}`]);
      updateElement(device.id, new Device(device));
      console.log(device);
    };

    // Subscribe to the SignalR service events
    signalRService.subscribe("UpdateDeviceStatus", handleUpdateDeviceStatus);

    return () => {
      // Clean up the event subscription when the component unmounts
      signalRService.unsubscribe(
        "UpdateDeviceStatus",
        handleUpdateDeviceStatus
      );
    };
  }, []); // Empty array ensures this effect runs only once on mount

  const fetchData = async (homeId) => {
    if (loading || isFetching.current) return; // Prevent duplicate calls if loading or already fetching
    setLoading(true); // Start the loader
    isFetching.current = true; // Mark that a fetch is in progress

    console.log("Ładowanie domu - fetchData");

    try {
      const savedFilter = localStorage.getItem("filterState");

      let selectedHomeId = homeId || null;

      if (savedFilter && !selectedHomeId) {
        const parsedFilter = JSON.parse(savedFilter);
        selectedHomeId =
          parsedFilter.home && parseInt(parsedFilter.home) !== 0
            ? parseInt(parsedFilter.home)
            : null;
      }

      const response = await fetchDevices(selectedHomeId);
      const devices = response.data.devices.map(
        (deviceData) => new Device(deviceData)
      );

      const foundHome = response.data.homes.find(
        (home) => home.homeId === selectedHomeId
      );

      if (foundHome && filterHome == null) {
        setFilterHome(foundHome);
      } else if (filterHome == null) {
        setFilterHome(response.data.homes[0] || null);
      }

      setData(devices);
      setRooms(response.data.rooms);
      setHomes(response.data.homes);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop the loader
      isFetching.current = false; // Mark that the fetch has completed
    }
  };
  const checkRelayState = (device) => {
    let switch1Value = false;

    for (const item of device.statusList) {
      if (item.code === "switch_1") {
        switch1Value = item.value;
        break;
      }
    }

    return switch1Value;
  };

  const handleFilterChanged = (appliedFilters) => {
    console.log(appliedFilters);

    // Only initiate a fetch if there is no fetch currently in progress and the home filter changes
    if (appliedFilters.home !== deviceFilter.home && !isFetching.current) {
      setLoading(true);
      setDeviceFilter(appliedFilters);
      fetchData(appliedFilters.home);
    } else {
      setDeviceFilter(appliedFilters);
    }
  };

  const updateElement = (id, updatedElement) => {
    setData((prevData) =>
      prevData.map((item) => (item.id === id ? updatedElement : item))
    );
  };

  const filterByDeviceType = (item) => {
    return (
      deviceFilter.deviceTypes == null ||
      deviceFilter.deviceTypes.length === 0 ||
      deviceFilter.deviceTypes.includes(item.deviceType)
    );
  };

  const filterByProductId = (item) => {
    return (
      deviceFilter.productIds == null ||
      deviceFilter.productIds.length === 0 ||
      deviceFilter.productIds.includes(item.productId)
    );
  };

  const filterByOnlineOffline = (item) => {
    return (
      deviceFilter.isOnline == null || deviceFilter.isOnline === item.isOnline
    );
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(deviceFilter.name.toLowerCase()) &&
      (deviceFilter.rooms == null ||
        deviceFilter.rooms.length === 0 ||
        item.rooms.some((id) => deviceFilter.rooms.includes(id.room_Id))) &&
      (deviceFilter.home === 0 || item.homeId === deviceFilter.home) &&
      filterByDeviceType(item) &&
      filterByProductId(item) &&
      filterByOnlineOffline(item)
  );

  const getRoomNameById = (id) => {
    // Assuming `rooms` is an array of room objects with 'id' and 'name' properties
    const room = rooms.find((room) => room.room_Id === parseInt(id));
    return room ? room.name : "Unknown Room";
  };

  // Group filteredData by rooms, defaulting to "Unassigned" if no room exists
  const groupedData = filteredData.reduce((acc, device) => {
    const room_Id =
      device.rooms && device.rooms.length > 0
        ? device.rooms[0].room_Id
        : "unassigned";
    if (!acc[room_Id]) {
      acc[room_Id] = [];
    }
    acc[room_Id].push(device);
    return acc;
  }, {});

  // Function to get the room name, defaulting to "Unassigned"
  const getRoomGroupName = (roomId) => {
    return roomId === "unassigned"
      ? translate("roomUnassigned", "Room unassigned")
      : getRoomNameById(roomId);
  };

  // Sort the grouped data keys
  const sortedRoomKeys = Object.keys(groupedData).sort((a, b) => {
    if (a === "unassigned") return -1;
    if (b === "unassigned") return 1;
    const roomAName = getRoomGroupName(a);
    const roomBName = getRoomGroupName(b);
    return roomAName.localeCompare(roomBName);
  });

  return (
    <RouteLayout
      title={translate("overview", "Przegląd")}
      fixed={
        <SerchFilterPanel
          onFilterChanged={handleFilterChanged}
          // onNameFilterChangedCallback={handleNameFilterChanged}
          // onRoomsFilterChangedCallback={handleRoomsFilterChanged}
          // onHomeFilterChangedCallback={handleHomeFilterChanged}
          rooms={rooms}
          homes={homes}
          selectedHome={filterHome}
          isLoading={loading}
        />
      }
    >
      {loading ? ( // Conditional rendering of the loader
        <Loader100>
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color={theme.color.base1}
            secondaryColor={theme.color.base1}
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Loader100>
      ) : (
        <Elements>
          <p>
            {sortedRoomKeys.length < 1 ? (
              <Empty>
                <Ikonka name="empty" />
                <p>{translate("dashboard_empty_1")}</p>
                <p>{translate("dashboard_empty_2")}</p>
              </Empty>
            ) : (
              ""
            )}
          </p>
          {/* Iterate over the grouped data */}
          {sortedRoomKeys.map((roomId) => (
            <>
              <RoomName>{getRoomGroupName(roomId)}</RoomName>
              {groupedData[roomId]
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort devices by name
                .map((device) => {
                  switch (device.deviceType) {
                    case DeviceTypeEnum.EngoThermostat:
                      return (
                        <ThermostatCard
                          key={device.id}
                          name={device.name}
                          deviceId={device.id}
                          device={device}
                          type={translate("deviceThermostat", "Thermostat")}
                          typeId={0}
                          rooms={device.rooms}
                          isOnline={device.isOnline}
                        />
                      );
                    case DeviceTypeEnum.EngoRelay:
                      return (
                        <RelayCard
                          key={device.id}
                          name={device.name}
                          deviceId={device.id}
                          statusOnOff={checkRelayState(device)}
                          type={translate("deviceRelay", "Relay")}
                          typeId={1}
                          rooms={device.rooms}
                          device={device}
                          isOnline={device.isOnline}
                        />
                      );
                    case DeviceTypeEnum.EngoUnderfloor:
                      return (
                        <Card
                          key={device.id}
                          name={device.name}
                          deviceId={device.id}
                          statusOnOff={checkRelayState(device)}
                          type={translate(
                            "deviceUnderfloor",
                            "Underfloor control box"
                          )}
                          typeId={2}
                          rooms={device.rooms}
                          device={device}
                          isOnline={device.isOnline}
                        />
                      );

                    case DeviceTypeEnum.EngoZigbeeGateway:
                      return (
                        <Card
                          key={device.id}
                          name={device.name}
                          deviceId={device.id}
                          statusOnOff={checkRelayState(device)}
                          type={translate("deviceOther", "Other")}
                          typeId={3}
                          rooms={device.rooms}
                          device={device}
                          isOnline={device.isOnline}
                        />
                      );
                    // Uncomment and use if needed
                    // default:
                    //   return (
                    //     <Card
                    //       key={device.id}
                    //       deviceId={device.id}
                    //       name={device.name}
                    //       type="Inny"
                    //       rooms="kuchnia"
                    //       device={device}
                    //     />
                    //   );
                  }
                })}
            </>
          ))}
        </Elements>
      )}
    </RouteLayout>
  );
};

export default DataComponent;
