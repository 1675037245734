import React, { useEffect, useState } from "react";
//DATA
import axios from "axios";
import termostat from "../../../assets/przekaznik.png";
//COMPONENTS
import OnOffButton from "../../Button/OnOffButton";
import CardWrapper from "./CardElements/CardWrapper";
import CardHeader from "./CardElements/CardHeader";
import InfoLine from "./CardElements/InfoLine";
import CardFastSettingsWrapper from "./CardElements/CardFastSettingsWrapper";
import { setOnDevice } from "../../../services/ApiService";
import CardOnOffButton from "./CardElements/CardOnOffButton";

function RelayCard({
  name,
  rooms,
  deviceId,
  statusOnOff,
  type,
  typeId,
  device,
  isOnline,
}) {
  //  console.log(name + ", status:  " + statusOnOff);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setIsClicked(statusOnOff);
  }, []);

  const handleClick = async () => {
    const data = {
      deviceId: deviceId,
      command: "switch_1",
      value: !isClicked,
    };

    setOnDevice(data)
      .then((response) => {
        console.log("Data posted successfully");
        setIsClicked(!isClicked);
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });

    // Code to be executed when the component is clicked
    console.log("Component clicked! " + deviceId);
  };

  return (
    <CardWrapper
      settings={
        <CardFastSettingsWrapper typ={type}>
          <OnOffButton onClick={handleClick} status={statusOnOff} />
          {/* <Button onClick={handleClick}>on/off</Button> */}
        </CardFastSettingsWrapper>
      }
    >
      <CardHeader
        name={name}
        type={type}
        typeId={typeId}
        deviceId={deviceId}
        onCliClick=""
        device={device}
        isOnline={isOnline}
      />
      <InfoLine
        rooms={rooms}
        onClick={handleClick}
        status={statusOnOff}
        isOnline={isOnline}
      />
      {isOnline && (
        <CardOnOffButton status={statusOnOff} onClick={handleClick} />
      )}
    </CardWrapper>
  );
}

export default RelayCard;
