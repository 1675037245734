import React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  -webkit-appearance: none; /* Hide default checkbox styling */
  appearance: none; /* Hide default checkbox styling */
  width: 24px;
  height: 24px;
  border: 2px solid ${theme.color.base1}; /* Green border */
  border-radius: 4px; /* Optional: add border radius */
  margin-right: 8px; /* Space between checkbox and label text */
  position: relative; /* Position for pseudo-elements */
  outline: none; /* Remove outline */

  &:checked {
    background-color: ${theme.color.base1}; /* Green background when checked */

    &::after {
      content: ""; /* Create a pseudo-element for the tick */
      position: absolute;
      left: 6px; /* Position of the tick */
      top: 1px; /* Position of the tick */
      width: 8px; /* Width of the tick */
      height: 14px; /* Height of the tick */
      border: solid white; /* Tick color */
      border-width: 0 3px 3px 0; /* Create the tick shape */
      transform: rotate(45deg); /* Rotate to form a tick */
      transition: all 0.2s; /* Optional: smooth transition for the tick */
    }
  }
`;

const CheckboxLabel = styled.span`
  cursor: pointer;
  &.checked {
    color: ${theme.color.base1};
    font-weight: 600;
  }
`;

const CustomCheckbox = ({ label_text, id, className, onChange, checked }) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox id={id} checked={checked} onChange={onChange} />
      <CheckboxLabel className={checked ? "checked" : ""}>
        {label_text}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export default CustomCheckbox;
