import React, { useEffect, useState } from "react";
//STYLES
import styled from "styled-components";
import { Heading } from "../../../../utils/utilsstyles";
//COMPONENTS
import CircSlider from "../../../Slider/circSlider/circSlider";
//FUNCTIONS
import _ from "lodash";
import { useLanguage } from "../../../../services/LanguageContext";

const Wrapper = styled.div`
  h3 {
    margin-bottom: 5px;
  }
`;

const SliderWrapper = styled.div`
  margin-top: 3rem;
`;

function ManualMode({ device }) {
  const { translate } = useLanguage();
  const [tempSet, setTempSet] = useState(23);

  useEffect(() => {
    // Update tempSet when the component mounts
    setTempSet(device.getStatusValueInTempScale("temp_set"));
  }, [device]); // useEffect dependency on device

  const handleArrowUpClick = () => {
    handleTempChange(positiveIncrement);
  };

  const handleArrowDownClick = () => {
    handleTempChange(negativeIncrement);
  };

  const handleTempChange = (isNegative) => {
    let tempIncrement = device.getStepRangeInTempScale("temp_set");
    let sign = isNegative ? -1 : 1;
    tempIncrement = tempIncrement * sign;
    let newTempSet = tempSet + tempIncrement;

    sendTemperatureUpdate(newTempSet);

    setTempSet(newTempSet);

    // Code to be executed when the component is clicked
    console.log("Component clicked! " + device.id);
  };

  const sendTemperatureUpdate = (temperature) => {
    device.setOnDeviceByParamsInTempScale("temp_set", temperature);
  };

  const handleOnChanged = (temperature) => {
    setTempSet(temperature);
  };

  const handleOnChangedDebounce = (temperature) => {
    device.setOnDeviceByParamsInTempScale("temp_set", temperature);
  };

  const negativeIncrement = true;
  const positiveIncrement = false;

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("manual_mode_temp_setting")}</h3>
      </Heading>
      <SliderWrapper>
        <CircSlider
          temperatureset={tempSet}
          onChanged={handleOnChanged}
          onChangedDebounce={handleOnChangedDebounce}
          minTemperature={device.getFromRangeInTempScale("temp_set")}
          maxTemperature={device.getToRangeInTempScale("temp_set")}
          buttonPluse={handleArrowUpClick}
          buttonMinus={handleArrowDownClick}
          step={device.getStepRangeInTempScale("temp_set")}
        />
      </SliderWrapper>
    </Wrapper>
  );
}

export default ManualMode;
