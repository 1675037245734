class TimeService {
  static convertToTimeFormat(hour, minute) {
    // Pad single digits with leading zeros and return the formatted time
    return `${String(hour).padStart(2, "0")}:${String(minute).padStart(
      2,
      "0"
    )}`;
  }

  static convertToTimeString(hour, minute) {
    const referenceDate = new Date(1970, 0, 1); // You can use any date here
    referenceDate.setHours(hour, minute, 0);
    return referenceDate.toISOString();
  }

  static convertToTimeStringMinuteSeconds(minute, second) {
    const referenceDate = new Date(1970, 0, 1); // You can use any date here
    referenceDate.setHours(0, minute, second);
    return referenceDate.toISOString();
  }
}

export default TimeService;
