import React from "react";
import styled from "styled-components";
import { Heading } from "../../utils/utilsstyles";
import Button from "../Button/Button";
import { useLanguage } from "../../services/LanguageContext";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  //left: 200px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: auto;
  min-width: 500px;
  max-width: 100%;
`;

const ModalChildren = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1rem 0;
  margin: 1rem 0;
  margin-bottom: 2rem;
  max-height: calc(100vh - 250px);
  overflow-y: ${(props) => (props.dropdownOpen ? "visible" : "scroll")};

  &.noHr {
    border: transparent;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
`;

const Modal = ({
  children,
  className,
  title,
  onClose,
  intro,
  confirmText,
  onConfirm,
  isValid = true,
}) => {
  const { translate } = useLanguage();
  return (
    <ModalOverlay>
      <ModalContainer>
        <Heading>
          {title && <h6>{title}</h6>}
          {intro && <p>{intro}</p>}
        </Heading>
        {children && (
          <ModalChildren className={className}>{children}</ModalChildren>
        )}

        <ModalFooter>
          <Button variant="white" onClick={onClose}>
            {translate("cancel")}
          </Button>
          {isValid && (
            <Button onClick={onConfirm}>
              {confirmText || translate("save")}
            </Button>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
