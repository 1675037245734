import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getLanguageTranslations,
  getAvailableLanguagesTranslationsForLanguage,
} from "./ApiService";
import Cookies from "js-cookie";

// Create the context
const LanguageContext = createContext();

// Create a provider component
export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default language is English
  const [translations, setTranslations] = useState({}); // State to store translations
  const [availableLanguages, setAvailableLanguages] = useState([]); // State to store available languages
  const [fetchStarted, setFetchStarted] = useState(false); // Default language is English

  useEffect(() => {
    const fetchTranslations = async () => {
      // Try to get the language from cookies or use the browser language
      const cookieLanguage = Cookies.get("language");
      const browserCulture = navigator.language || navigator.userLanguage;
      const currentLanguage = cookieLanguage || browserCulture || "en"; // Default to English if no language is set

      const result = await getAvailableLanguagesTranslationsForLanguage(
        currentLanguage
      );

      setAvailableLanguages(result.languages);
      setTranslations(result.translations);
      setSelectedLanguage(result.languageCode);

      Cookies.set("language", result.languageCode, { expires: 365 * 10 });
    };

    if (!fetchStarted) {
      fetchTranslations();
    }

    setFetchStarted(true);
  }, []); // Empty dependency array to trigger fetch only on mount

  const changeLanguage = async (lang) => {
    const result = await getLanguageTranslations(lang);

    Cookies.set("language", lang, { expires: 365 * 10 });

    setTranslations(result.translations);
    setSelectedLanguage(result.languageCode);
  };

  const translate = (key, fallbackValue) => {
    return translations[key] || fallbackValue || key; // Return translation or fallback to the key itself if not found
  };

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        changeLanguage,
        translate,
        availableLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);
