import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";

import { Heading } from "../../../../utils/utilsstyles";
import { Ikonka } from "../../../Icons/Icon";
import Slider from "../../../Slider/Slider";
//FUNCTIONS
import { useLanguage } from "../../../../services/LanguageContext";

const Wrapper = styled.div`
  h3 {
    margin-bottom: 5px;
  }

  > div {
    &:nth-last-of-type(1) {
      margin-top: 2rem;
    }
  }
`;

const Empty = styled.div`
  display: flex;
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;

  svg {
    height: 100px;
    margin-bottom: 1rem;
    fill: ${theme.color.base1};
  }

  p {
    font-size: 18px;
    line-height: 1.3;
  }
`;

const SettingsWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

function FrostMode({ device }) {
  // const frost_set = "frost_set";
  const frost_set = device.checkIfPropertyExists("frost_set")
    ? "frost_set"
    : device.checkIfPropertyExists("frost_protect_temp")
    ? "frost_protect_temp"
    : device.checkIfPropertyExists("frost_temp_set")
    ? "frost_temp_set"
    : "";

  const { translate } = useLanguage();

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("frost_mode")}</h3>
      </Heading>

      <SettingsWrapper>
        {frost_set !== "" ? (
          <Slider
            icon="temperature"
            label={translate("frost_temp")}
            value={device.getStatusValueInTempScale(frost_set)}
            min={device.getFromRangeInTempScale(frost_set)}
            max={device.getToRangeInTempScale(frost_set)}
            step={device.getStepRangeInTempScale(frost_set)}
            onChange={(value) => {
              console.log("frost_set changed to value:" + value);
              device.setOnDeviceByParamsInTempScale(frost_set, value);
            }}
          />
        ) : (
          <Empty>
            <Ikonka name="empty" />
            <p>{translate("frost_empty")}</p>
          </Empty>
        )}
      </SettingsWrapper>
    </Wrapper>
  );
}

export default FrostMode;
