import React from "react";
import styled from "styled-components";
import { Ikonka } from "../Icons/Icon";
import { theme } from "../../utils/theme";

const Button = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: ${(props) =>
    props.isActive ? theme.color.base1 : theme.color.base6};
  color: ${theme.color.base1};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  padding: 0;

  &:before {
    position: absolute;
    color: ${(props) =>
      props.isActive ? theme.color.base1 : theme.color.font};
    font-size: 14px;
    top: 42px;
    font-family: ${theme.font.header};
    font-weight: ${(props) => (props.isActive ? 500 : 200)};
    content: "${(props) => props.before}";
    transition: all 0.6s ease-in-out;
    min-width: 40px;
  }

  svg {
    fill: ${(props) =>
      props.isActive ? theme.color.base9 : theme.color.base1};
    max-width: 24px;
    min-height: 24px;
    transition: all 0.6s ease-in-out;

    &:hover {
      background-color: transparent;
    }
  }

  h6 {
    color: ${(props) =>
      props.isActive ? theme.color.white : theme.color.base1};
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    transition: all 0.6s ease-in-out;
  }

  &:hover {
    background-color: ${theme.color.prim1};
    &:before {
      color: ${theme.color.prim1};
      font-weight: 500;
    }
    svg {
      fill: ${theme.color.base9};
    }

    h6 {
      background-color: transparent;
      color: white;
    }
  }

  &:focus {
    outline: none;
  }

  &:active {
    &:before {
      color: ${theme.color.base1};
      font-weight: 500;
    }

    background-color: ${theme.color.base1};
    svg {
      fill: ${theme.color.base9};
    }
  }

  &.on,
  &.off {
    cursor: default;
  }

  &.on {
    background-color: ${theme.color.base1};
    h6 {
      color: ${theme.color.white};
    }
  }

  &.off {
    background-color: ${theme.color.second};
    opacity: 0.4;
    h6 {
      color: ${theme.color.white};
    }
  }
`;

const ButtonRounded = ({
  onClick,
  buttonLabel,
  label,
  icon,
  active,
  text,
  className,
}) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      before={label}
      isActive={active}
    >
      {buttonLabel}
      {icon ? <Ikonka name={icon} /> : null}
      <h6>{text}</h6>
    </Button>
  );
};

export default ButtonRounded;
