import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../utils/theme";
//DATA

//COMPONENTS
import LeftSidePanel from "../layout/LeftSidePanel/LeftSidePanel";

const LayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  background-color: ${theme.color.sec7};
  //background-color: green;
  //overflow: hidden;
`;

function Layout({ children, onClick }) {
  return (
    <LayoutWrapper>
      <LeftSidePanel onClick={onClick} />
      <div>{children}</div>
    </LayoutWrapper>
  );
}

export default Layout;
