import React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Label = styled.label`
  color: ${theme.color.sec4};
  font-size: 18px;
`;

const Inp = styled.input`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    margin-top: 2px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 22px;
    background: white;
    border: 2px solid ${theme.color.sec2};
    border-radius: 4px;
  }

  &:hover + label:before {
    background: ${theme.color.base1};
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: ${theme.color.base1};
    border: 2px solid ${theme.color.base1};
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    margin-top: 2px;
    left: 5px;
    top: 13px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, -2px 0 0 white, 4px -2px 0 white,
      4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white, 4px -10px 0 white,
      4px -12px 0 white;
    transform: rotate(45deg);
  }

  &.error + label:before {
    border: 2px solid red;
  }

  &:hover + label:before + &.error {
    background: red;
  }
`;

const CheckBox = ({ label_text, id, className, onChange, checked }) => (
  <div
    style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}
  >
    <input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange} // Handle change event
    />
    <Label className={className} htmlFor={id}>
      {label_text}
    </Label>
  </div>
);

export default CheckBox;
