import React, { createContext, useContext } from "react";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import Cookies from "js-cookie";

const SERVER_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_PRODUCTION;

const HUB_BASE_URL = SERVER_BASE_URL + "/realtime-updates-hub";

// Create a Context for the SignalR Service
const SignalRContext = createContext(null);

// Create a Singleton SignalRService Instance
let signalRServiceInstance = null;

class SignalRService {
  constructor() {
    if (signalRServiceInstance) {
      return signalRServiceInstance;
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(HUB_BASE_URL, {
        accessTokenFactory: () => Cookies.get("token"),
        transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .build();

    this.subscribers = {};

    this.connection
      .start()
      .then(() => {
        console.log("SignalR Connected.");

        // Set up event listener
        this.connection.on("UpdateDeviceStatus", (device) => {
          this.notifySubscribers("UpdateDeviceStatus", device);
        });

        // Start sending a message every 20 seconds
        this.startSendingHeartbeat();
      })
      .catch((err) => console.log("SignalR Connection Error: ", err));

    this.connection.serverTimeoutInMilliseconds = 3600000;

    signalRServiceInstance = this;
    Object.freeze(this);
  }

  // Method to send a message to the server
  sendHeartbeat() {
    this.connection
      .invoke("ReceiveHeartbeat") // Ensure this matches the method name on the server
      .then(() => console.log("Heartbeat sent"))
      .catch((err) => console.error("Error sending heartbeat: ", err));
  }

  // Method to start sending the heartbeat every 20 seconds
  startSendingHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      this.sendHeartbeat();
    }, 20000); // 20 seconds
  }

  // Method to stop sending the heartbeat (optional)
  stopSendingHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }
  }

  subscribe(event, callback) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }
    this.subscribers[event].push(callback);
  }

  unsubscribe(event, callback) {
    if (!this.subscribers[event]) return;

    this.subscribers[event] = this.subscribers[event].filter(
      (subscriber) => subscriber !== callback
    );

    if (this.subscribers[event].length === 0) {
      delete this.subscribers[event];
    }
  }

  notifySubscribers(event, data) {
    if (!this.subscribers[event]) return;
    this.subscribers[event].forEach((callback) => callback(data));
  }
}

// Create a Singleton Instance
if (!signalRServiceInstance) {
  signalRServiceInstance = new SignalRService();
}

export const SignalRProvider = ({ children }) => {
  return (
    <SignalRContext.Provider value={signalRServiceInstance}>
      {children}
    </SignalRContext.Provider>
  );
};

// Create a hook to use the SignalR Service
export const useSignalR = () => {
  const context = useContext(SignalRContext);
  if (!context) {
    throw new Error("useSignalR must be used within a SignalRProvider");
  }
  return context;
};
