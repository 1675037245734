export const week = [
  { name: "Monday", label: "PN", active: true },
  { name: "Tuesday", label: "WT", active: false },
  { name: "Wednesday", label: "SR", active: false },
  { name: "Thursday", label: "CZ", active: false },
  { name: "Friday", label: "PT", active: false },
  { name: "Saturday", label: "SB", active: false },
  { name: "Sunday", label: "ND", active: false },
];

export const day_hours = {
  Poniedziałek: [
    {
      name: "wczesny ranek poniedziałek",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    {
      name: "rano poniedziałek",
      start_hour: "9:00",
      temp_set: 22,
      icon: "morning2",
    },
    {
      name: "południe poniedziałek",
      start_hour: "12:00",
      temp_set: 25,
      icon: "noon",
    },
    {
      name: "popołudnie poniedziałek",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór poniedziałek",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    {
      name: "noc poniedziałek",
      start_hour: "21:00",
      temp_set: 19,
      icon: "night",
    },
  ],
  Wtorek: [
    {
      name: "wczesny ranek wtorek",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    { name: "rano wtorek", start_hour: "9:00", temp_set: 22, icon: "morning2" },
    {
      name: "południe wtorek",
      start_hour: "12:00",
      temp_set: 25,
      icon: "noon",
    },
    {
      name: "popołudnie wtorek",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór wtorek",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    { name: "noc wtorek", start_hour: "21:00", temp_set: 19, icon: "night" },
  ],
  Środa: [
    {
      name: "wczesny ranek środa",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    { name: "rano środa", start_hour: "9:00", temp_set: 22, icon: "morning2" },
    { name: "południe środa", start_hour: "12:00", temp_set: 25, icon: "noon" },
    {
      name: "popołudnie środa",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór środa",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    { name: "noc środa", start_hour: "21:00", temp_set: 19, icon: "night" },
  ],
  Czwartek: [
    {
      name: "wczesny ranek czwartek",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    {
      name: "rano czwartek",
      start_hour: "9:00",
      temp_set: 22,
      icon: "morning2",
    },
    {
      name: "południe czwartek",
      start_hour: "12:00",
      temp_set: 25,
      icon: "noon",
    },
    {
      name: "popołudnie czwartek",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór czwartek",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    { name: "noc czwartek", start_hour: "21:00", temp_set: 19, icon: "night" },
  ],
  Piątek: [
    {
      name: "wczesny ranek piątek",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    { name: "rano piątek", start_hour: "9:00", temp_set: 22, icon: "morning2" },
    {
      name: "południe piątek",
      start_hour: "12:00",
      temp_set: 25,
      icon: "noon",
    },
    {
      name: "popołudnie piątek",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór piątek",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    { name: "noc piątek", start_hour: "21:00", temp_set: 19, icon: "night" },
  ],
  Sobota: [
    {
      name: "wczesny ranek sobota",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    { name: "rano sobota", start_hour: "9:00", temp_set: 22, icon: "morning2" },
    {
      name: "południe sobota",
      start_hour: "12:00",
      temp_set: 25,
      icon: "noon",
    },
    {
      name: "popołudnie sobota",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór sobota",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    { name: "noc sobota", start_hour: "21:00", temp_set: 19, icon: "night" },
  ],
  Niedziela: [
    {
      name: "wczesny ranek niedziela",
      start_hour: "6:00",
      temp_set: 20,
      icon: "morning1",
    },
    {
      name: "rano niedziela",
      start_hour: "9:00",
      temp_set: 22,
      icon: "morning2",
    },
    {
      name: "południe niedziela",
      start_hour: "12:00",
      temp_set: 25,
      icon: "noon",
    },
    {
      name: "popołudnie niedziela",
      start_hour: "15:00",
      temp_set: 23,
      icon: "afternoon",
    },
    {
      name: "wieczór niedziela",
      start_hour: "18:00",
      temp_set: 21,
      icon: "evening",
    },
    { name: "noc niedziela", start_hour: "21:00", temp_set: 19, icon: "night" },
  ],
};

export const devices = [
  {
    device_Id: "Termostat",
    name: "Termostat",
    models: [
      {
        value: "1",
        label: "termostat Typ 1",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
      {
        value: "2",
        label: "termostat Typ 2",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
      {
        value: "3",
        label: "termostat Typ 3",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
    ],
  },
  {
    device_Id: "Listwa",
    name: "Listwa",
    models: [
      {
        value: "1",
        label: "Listwa Typ 1",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
      {
        value: "2",
        label: "Listwa Typ 2",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
    ],
  },
  {
    device_Id: "Przekaźnik",
    name: "Przekaźnik",
    models: [
      {
        value: "1",
        label: "Przekaźnik Typ 1",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
      {
        value: "2",
        label: "Przekaźnik Typ 2",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
      {
        value: "3",
        label: "Przekaźnik Typ 3",
        status: [
          { value: "on", label: "dost. online" },
          { value: "off", label: "offline" },
        ],
      },
    ],
  },
];

export const dropdown = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
];

export const hours = [
  { value: 0, label: "00 h" },
  { value: 1, label: "01 h" },
  { value: 2, label: "02 h" },
  { value: 3, label: "03 h" },
  { value: 4, label: "04 h" },
  { value: 5, label: "05 h" },
  { value: 6, label: "06 h" },
  { value: 7, label: "07 h" },
  { value: 8, label: "08 h" },
  { value: 9, label: "09 h" },
  { value: 10, label: "10 h" },
  { value: 11, label: "11 h" },
  { value: 12, label: "12 h" },
  { value: 13, label: "13 h" },
  { value: 14, label: "14 h" },
  { value: 15, label: "15 h" },
  { value: 16, label: "16 h" },
  { value: 17, label: "17 h" },
  { value: 18, label: "18 h" },
  { value: 19, label: "19 h" },
  { value: 20, label: "20 h" },
  { value: 21, label: "21 h" },
  { value: 22, label: "22 h" },
  { value: 23, label: "23 h" },
];

export const minutes = [
  { value: 0, label: "00 min" },
  { value: 1, label: "01 min" },
  { value: 2, label: "02 min" },
  { value: 3, label: "03 min" },
  { value: 4, label: "04 min" },
  { value: 5, label: "05 min" },
  { value: 6, label: "06 min" },
  { value: 7, label: "07 min" },
  { value: 8, label: "08 min" },
  { value: 9, label: "09 min" },
  { value: 10, label: "10 min" },
  { value: 11, label: "11 min" },
  { value: 12, label: "12 min" },
  { value: 13, label: "13 min" },
  { value: 14, label: "14 min" },
  { value: 15, label: "15 min" },
  { value: 16, label: "16 min" },
  { value: 17, label: "17 min" },
  { value: 18, label: "18 min" },
  { value: 19, label: "19 min" },
  { value: 20, label: "20 min" },
  { value: 21, label: "21 min" },
  { value: 22, label: "22 min" },
  { value: 23, label: "23 min" },
  { value: 24, label: "24 min" },
  { value: 25, label: "25 min" },
  { value: 26, label: "26 min" },
  { value: 27, label: "27 min" },
  { value: 28, label: "28 min" },
  { value: 29, label: "29 min" },
  { value: 30, label: "30 min" },
  { value: 31, label: "31 min" },
  { value: 32, label: "32 min" },
  { value: 33, label: "33 min" },
  { value: 34, label: "34 min" },
  { value: 35, label: "35 min" },
  { value: 36, label: "36 min" },
  { value: 37, label: "37 min" },
  { value: 38, label: "38 min" },
  { value: 39, label: "39 min" },
  { value: 40, label: "40 min" },
  { value: 41, label: "41 min" },
  { value: 42, label: "42 min" },
  { value: 43, label: "43 min" },
  { value: 44, label: "44 min" },
  { value: 45, label: "45 min" },
  { value: 46, label: "46 min" },
  { value: 47, label: "47 min" },
  { value: 48, label: "48 min" },
  { value: 49, label: "49 min" },
  { value: 50, label: "50 min" },
  { value: 51, label: "51 min" },
  { value: 52, label: "52 min" },
  { value: 53, label: "53 min" },
  { value: 54, label: "54 min" },
  { value: 55, label: "55 min" },
  { value: 56, label: "56 min" },
  { value: 57, label: "57 min" },
  { value: 58, label: "58 min" },
  { value: 59, label: "59 min" },
];

export const seconds = [
  { value: 0, label: "00 sec" },
  { value: 1, label: "01 sec" },
  { value: 2, label: "02 sec" },
  { value: 3, label: "03 sec" },
  { value: 4, label: "04 sec" },
  { value: 5, label: "05 sec" },
  { value: 6, label: "06 sec" },
  { value: 7, label: "07 sec" },
  { value: 8, label: "08 sec" },
  { value: 9, label: "09 sec" },
  { value: 10, label: "10 sec" },
  { value: 11, label: "11 sec" },
  { value: 12, label: "12 sec" },
  { value: 13, label: "13 sec" },
  { value: 14, label: "14 sec" },
  { value: 15, label: "15 sec" },
  { value: 16, label: "16 sec" },
  { value: 17, label: "17 sec" },
  { value: 18, label: "18 sec" },
  { value: 19, label: "19 sec" },
  { value: 20, label: "20 sec" },
  { value: 21, label: "21 sec" },
  { value: 22, label: "22 sec" },
  { value: 23, label: "23 sec" },
  { value: 24, label: "24 sec" },
  { value: 25, label: "25 sec" },
  { value: 26, label: "26 sec" },
  { value: 27, label: "27 sec" },
  { value: 28, label: "28 sec" },
  { value: 29, label: "29 sec" },
  { value: 30, label: "30 sec" },
  { value: 31, label: "31 sec" },
  { value: 32, label: "32 sec" },
  { value: 33, label: "33 sec" },
  { value: 34, label: "34 sec" },
  { value: 35, label: "35 sec" },
  { value: 36, label: "36 sec" },
  { value: 37, label: "37 sec" },
  { value: 38, label: "38 sec" },
  { value: 39, label: "39 sec" },
  { value: 40, label: "40 sec" },
  { value: 41, label: "41 sec" },
  { value: 42, label: "42 sec" },
  { value: 43, label: "43 sec" },
  { value: 44, label: "44 sec" },
  { value: 45, label: "45 sec" },
  { value: 46, label: "46 sec" },
  { value: 47, label: "47 sec" },
  { value: 48, label: "48 sec" },
  { value: 49, label: "49 sec" },
  { value: 50, label: "50 sec" },
  { value: 51, label: "51 sec" },
  { value: 52, label: "52 sec" },
  { value: 53, label: "53 sec" },
  { value: 54, label: "54 sec" },
  { value: 55, label: "55 sec" },
  { value: 56, label: "56 sec" },
  { value: 57, label: "57 sec" },
  { value: 58, label: "58 sec" },
  { value: 59, label: "59 sec" },
];

export const settingTimes = [
  {
    time: "13:40",
    days: ["pn", "wt"],
    onRemove: true,
    toggleSwitch: true,
    isOn: true,
    openTime: "1h 30min",
    closeTime: "3h",
    startTime: "13:45",
    endTime: "16:45",
  },
  {
    time: "18:30",
    days: ["pn", "wt"],
    onRemove: true,
    toggleSwitch: true,
    isOn: false,
    openTime: "2h",
    closeTime: "4h",
    startTime: "18:30",
    endTime: "22:30",
  },
  {
    time: "22:30",
    days: ["pn", "wt", "śr", "cz", "pt", "sb", "nd"],
    onRemove: true,
    toggleSwitch: true,
    isOn: false,
    openTime: "2h 30min",
    closeTime: "4h 30min",
    startTime: "22:30",
    endTime: "03:00",
  },
  {
    time: "11:00",
    days: ["pt", "sb", "nd"],
    onRemove: true,
    toggleSwitch: true,
    isOn: true,
    openTime: "1h 45min",
    closeTime: "3h 45min",
    startTime: "11:15",
    endTime: "15:00",
  },
  {
    time: "08:00",
    days: ["śr", "pt", "nd"],
    onRemove: true,
    toggleSwitch: true,
    isOn: true,
    openTime: "1h 15min",
    closeTime: "3h 15min",
    startTime: "08:15",
    endTime: "11:15",
  },
  {
    time: "13:40",
    days: ["pn", "wt"],
    onRemove: true,
    toggleSwitch: true,
    isOn: true,
    openTime: "1h 30min",
    closeTime: "3h",
    startTime: "13:45",
    endTime: "16:45",
  },
  {
    time: "18:30",
    days: ["pn", "wt"],
    onRemove: true,
    toggleSwitch: true,
    isOn: false,
    openTime: "2h",
    closeTime: "4h",
    startTime: "18:30",
    endTime: "22:30",
  },
  {
    time: "22:30",
    days: ["pn", "wt", "śr", "cz", "pt", "sb", "nd"],
    onRemove: true,
    toggleSwitch: true,
    isOn: false,
    openTime: "2h 30min",
    closeTime: "4h 30min",
    startTime: "22:30",
    endTime: "03:00",
  },
  {
    time: "11:00",
    days: ["pt", "sb", "nd"],
    onRemove: true,
    toggleSwitch: true,
    isOn: true,
    openTime: "1h 45min",
    closeTime: "3h 45min",
    startTime: "11:15",
    endTime: "15:00",
  },
  {
    time: "08:00",
    days: ["śr", "pt", "nd"],
    onRemove: true,
    toggleSwitch: true,
    isOn: true,
    openTime: "1h 15min",
    closeTime: "3h 15min",
    startTime: "08:15",
    endTime: "11:15",
  },
];
