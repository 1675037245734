import axios from "axios";
import Cookies from "js-cookie";

const SERVER_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_LOCAL
    : process.env.REACT_APP_API_URL_PRODUCTION;

const API_BASE_URL = SERVER_BASE_URL + "/api";

// REST API configuration
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  // headers: { Authorization: `Bearer ` },
  // withCredentials: true,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("token"); // Synchronously get the token from cookies
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`; // Set the Authorization header
    }
    return config; // Return the modified config to proceed with the request
  },
  (error) => {
    return Promise.reject(error); // Reject the promise with the error
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  // (config) => {
  //   const accessToken = Cookies.get("token"); // or wherever you store it
  //   if (accessToken) {
  //     config.headers["Authorization"] = `Bearer ${accessToken}`;
  //   }
  //   return config;
  // },
  (error) => {
    // handle unathorized request
    if (error.response && error.response.status === 401) {
      const event = new Event("unauthorized");
      window.dispatchEvent(event);
    }
    return Promise.reject(error);
  }
);

// get devices for the user
export const fetchDevices = async (homeId) => {
  try {
    const response = await apiClient.get("/Devices/GetList", {
      params: { homeId: homeId },
    });
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// set property/command on the device
export const setOnDevice = async (data) => {
  try {
    const response = await apiClient.post("/Devices/SetOnDevice", data);
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const setOnDeviceByParams = async (deviceId, command, value) => {
  try {
    let data = {
      deviceId: deviceId,
      command: command,
      value: value,
    };

    const response = await apiClient.post("/Devices/SetOnDevice", data);
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const saveProgram = async (deviceId, program) => {
  let setProgramDto = {
    deviceId: deviceId,
    program: program,
  };

  try {
    const response = await apiClient.post("/Devices/SetProgram", setProgramDto);
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const copyProgramToDays = async (
  deviceId,
  program,
  activeDay,
  copyToDays
) => {
  let copyProgramDto = {
    deviceId: deviceId,
    program: program,
    activeDay: activeDay,
    copyToDays: copyToDays,
  };

  try {
    const response = await apiClient.post(
      "/Devices/CopyProgramDays",
      copyProgramDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const setDeviceName = async (deviceId, deviceName) => {
  let setDeviceNameDto = {
    deviceId: deviceId,
    name: deviceName,
  };

  try {
    const response = await apiClient.post("/Devices/SetName", setDeviceNameDto);
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const setRelayAutoOff = async (deviceId, minute, second, isOn) => {
  let setRelayAutoOffDto = {
    deviceId: deviceId,
    time: {
      minute: minute,
      second: second,
    },
    isOn: isOn,
  };

  try {
    const response = await apiClient.post(
      "/Devices/SetRelayAutoOff",
      setRelayAutoOffDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const setRelayRandomEntry = async (deviceId, entries) => {
  let setRelayRandomEntryDto = {
    deviceId: deviceId,
    randomProgram: {
      entries: entries,
    },
  };

  try {
    const response = await apiClient.post(
      "/Devices/SetRelayRandomProgram",
      setRelayRandomEntryDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const setCycleTimingEntry = async (deviceId, entries) => {
  let setCycleTimingEntryDto = {
    deviceId: deviceId,
    cycleTimingProgram: {
      entries: entries,
    },
  };

  try {
    const response = await apiClient.post(
      "/Devices/SetRelayCycleTimingProgram",
      setCycleTimingEntryDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const updateRelayProgramEntry = async (deviceId, entry) => {
  let updateRelayProgramEntryDto = {
    deviceId: deviceId,
    programEntry: entry,
  };

  try {
    const response = await apiClient.post(
      "/Devices/UpdateRelayProgramEntry",
      updateRelayProgramEntryDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const addRelayProgramEntry = async (deviceId, entry) => {
  let addRelayProgramEntryDto = {
    deviceId: deviceId,
    programEntry: entry,
  };

  try {
    const response = await apiClient.post(
      "/Devices/AddRelayProgramEntry",
      addRelayProgramEntryDto
    );
    return response?.data?.groupId ?? null;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const updateRelayTimingStatus = async (deviceId, groupId, isEnabled) => {
  let updateRelayProgramStatusCommandDto = {
    deviceId: deviceId,
    groupId: groupId,
    isEnabled: isEnabled,
  };

  try {
    const response = await apiClient.post(
      "/Devices/UpdateRelayTimingStatus",
      updateRelayProgramStatusCommandDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const deleteRelayTiming = async (deviceId, groupId) => {
  try {
    const response = await apiClient.delete(
      `/Devices/DeleteRelayTiming/${deviceId}/group/${groupId}`
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// login user
export const loginUser = async (credentials) => {
  const apiClientLogin = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  try {
    const response = await apiClientLogin.post("/users/login", credentials);
    return response;
  } catch (error) {
    console.error("Error logging in:", error);
    return error.response;
  }
};

// get translation for language
export const getLanguageTranslations = async (language) => {
  try {
    const response = await apiClient.get(
      "/Translations/GetTranslationsForLanguage",
      {
        params: { language: language },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get available languages and translation for language
export const getAvailableLanguagesTranslationsForLanguage = async (
  language
) => {
  try {
    const response = await apiClient.get(
      "/Translations/GetAvailableLanguagesTranslationsForLanguage",
      {
        params: { language: language },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get translation for language
export const loadDeviceDetails = async (deviceId) => {
  try {
    const response = await apiClient.get("/Devices/GetDeviceDetails", {
      params: { deviceId: deviceId },
    });
    return response.data;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get translation for language
export const getRoomsForHome = async (homeId) => {
  try {
    const response = await apiClient.get("/Homes/GetRooms", {
      params: { homeId: homeId },
    });
    return response.data;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get translation for language
export const setRoomToDevice = async (deviceId, homeId, roomId) => {
  try {
    let setDeviceRoomDto = {
      deviceId: deviceId,
      homeId: homeId,
      roomId: roomId,
    };

    const response = await apiClient.post("/Devices/SetRoom", setDeviceRoomDto);
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get devices for the user
export const getSameTypeDevices = async (deviceId) => {
  try {
    const response = await apiClient.get("/Devices/GetSameTypeDevices", {
      params: { deviceId: deviceId },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get devices with holiday mode
export const getThermostatsWithHolidayMode = async (deviceId) => {
  try {
    const response = await apiClient.get(
      "/Devices/GetThermostatsWithHolidayMode",
      {
        params: { deviceId: deviceId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get devices for the user
export const getDeviceLogs = async (deviceId) => {
  try {
    const response = await apiClient.get("/Devices/GetDeviceLogs", {
      params: { deviceId: deviceId },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// get devices for the user
export const copyProgramToDevices = async (
  deviceId,
  program,
  copyToDeviceIds
) => {
  let copyProgramDto = {
    deviceId: deviceId,
    program: program,
    copyToDeviceIds: copyToDeviceIds,
  };

  try {
    const response = await apiClient.post(
      "/Devices/CopyProgramToDevices",
      copyProgramDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

export const copyHolidayToDevices = async (
  deviceId,
  holidayTemperature,
  holidayDays,
  copyToDeviceIds
) => {
  let copyHolidayDto = {
    deviceId: deviceId,
    holidayTemperature: holidayTemperature,
    holidayDays: holidayDays,
    copyToDeviceIds: copyToDeviceIds,
  };

  try {
    const response = await apiClient.post(
      "/Devices/CopyHolidayToDevices",
      copyHolidayDto
    );
    return response;
  } catch (error) {
    console.error("Error executing command:", error);
    // Optionally handle error internally or throw it to be handled where the function is called
    throw error;
  }
};

// Add more functions as needed
