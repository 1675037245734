import React, { useState, useEffect } from "react";

//STYLES
import styled from "styled-components";

//UTILS
import { useLanguage } from "../../../../services/LanguageContext";

//COMPONENTS
import Modal from "../../../Modals/Modal";
import { ButtonsWrapper } from "../../Termostat/WorkingModes/HarmonogramMode";
import Timer from "../../../Forms/Timer";
import Switch from "../../../Switch/Switch";
import ButtonRounded from "../../../Button/ButtonRounded";
import HourMinute from "../../../../services/utils/HourMinute";
import { RelayActionType } from "../../../../services/utils/RelayActionType";

const Period = styled.div`
  margin-bottom: 1rem;
  p.valid {
    color: red;
    font-weight: 500;
  }
`;

const RelayEntrySettingModal = ({
  week,
  toggleModal,
  title,
  intro,
  initialWeekDays,
  initialStartTime,
  initialEndTime,
  initialIsOn,
  initialKey,
  isSupportingOnOffTimes = false,
  isSupportingEndTimes = true,
  initialOnTime,
  initialOffTime,
  entryChangedHandler,
  entry,
  entryId,
  onConfirmAction,
}) => {
  const { translate } = useLanguage();

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();

  const [activeDays, setActiveDays] = useState(
    initialWeekDays ? initialWeekDays : []
  );
  const [isOn, setIsOn] = useState(initialIsOn ? initialIsOn : false);

  const [startTime, setStartTime] = useState(
    initialStartTime
      ? new HourMinute(initialStartTime.hour, initialStartTime.minute)
      : new HourMinute(currentHour, currentMinute)
  );
  const [endTime, setEndTime] = useState(
    initialEndTime
      ? new HourMinute(initialEndTime.hour, initialEndTime.minute)
      : new HourMinute(
          currentHour + 1 > 23 ? 0 : currentHour + 1,
          currentMinute
        )
  );

  const [onTime, setOnTime] = useState(
    initialOnTime
      ? new HourMinute(initialOnTime.hour, initialOnTime.minute)
      : null
  );

  const [offTime, setOffTime] = useState(
    initialOnTime
      ? new HourMinute(initialOffTime.hour, initialOffTime.minute)
      : null
  );

  const minTime = new HourMinute(0, 0);
  const minTimeOnOff = new HourMinute(0, 1);
  const maxTime = new HourMinute(23, 59);

  const startTimeChangedHandler = (hour, minute, isValid) => {
    setStartTime(new HourMinute(hour, minute, isValid));
  };

  const endTimeChangedHandler = (hour, minute, isValid) => {
    setEndTime(new HourMinute(hour, minute, isValid));
  };

  const onTimeChangedHandler = (hour, minute, isValid) => {
    setOnTime(new HourMinute(hour, minute, isValid));
  };

  const offTimeChangedHandler = (hour, minute, isValid) => {
    setOffTime(new HourMinute(hour, minute, isValid));
  };

  const handleSelectDayClick = (dayName) => {
    setActiveDays((prevDays) => {
      if (prevDays.includes(dayName)) {
        // If the day is already active, remove it from the list
        const collection = prevDays.filter((day) => day !== dayName);
        return collection;
      } else {
        // If the day is not active, add it to the list
        const collection = [...prevDays, dayName];
        return collection;
      }
    });
  };

  const handleConfirmProgram = () => {
    entryChangedHandler({
      time: {
        hour: startTime.hour,
        minute: startTime.minute,
      },
      isRelayOn: isOn,
      daysOfWeek: activeDays,
      groupId: entryId,
    });
  };

  const handleConfirm = () => {
    entryChangedHandler({
      startTime: {
        hour: startTime.hour,
        minute: startTime.minute,
      },
      endTime: {
        hour: endTime.hour,
        minute: endTime.minute,
      },
      isOn: isOn,
      daysOfWeek: activeDays,
    });
  };

  const handleConfirmWithOnOffTimes = () => {
    entryChangedHandler({
      startTime: {
        hour: startTime.hour,
        minute: startTime.minute,
      },
      endTime: {
        hour: endTime.hour,
        minute: endTime.minute,
      },
      onDuration: {
        hour: onTime.hour,
        minute: onTime.minute,
      },
      offDuration: {
        hour: offTime.hour,
        minute: offTime.minute,
      },
      isOn: isOn,
      daysOfWeek: activeDays,
    });
  };

  return (
    <Modal
      title={title}
      intro={intro}
      onClose={toggleModal}
      confirmText={entry ? translate("save") : translate("add")}
      isValid={
        isSupportingOnOffTimes
          ? offTime && offTime.isValid && onTime && onTime.isValid
          : true
      }
      onConfirm={() => {
        switch (onConfirmAction) {
          case RelayActionType.PROGRAM:
            handleConfirmProgram();
            break;
          case RelayActionType.CYCLIC:
            handleConfirmWithOnOffTimes();
            break;
          case RelayActionType.RANDOM:
            handleConfirm();
            break;
          default:
            break;
        }
        // isSupportingOnOffTimes ? handleConfirmWithOnOffTimes() : handleConfirm()
      }} // Replace with actual logic
    >
      <ButtonsWrapper>
        {week.map((day) => (
          <ButtonRounded
            key={day.name}
            text={translate(day.name)}
            active={activeDays.includes(day.name)}
            onClick={() => handleSelectDayClick(day.name)}
          />
        ))}
      </ButtonsWrapper>
      <Timer
        label={translate("relay_start_hour")}
        timeChanged={startTimeChangedHandler}
        hour={startTime.hour}
        minute={startTime.minute}
        minTime={minTime.getIsoTime()}
        maxTime={maxTime.getIsoTime()}
      />

      {!startTime.isValid && (
        <Period>
          <p className="valid">
            {translate("choose_beetween")}:{" "}
            <b>
              {minTime.getHHMMTime()} - {maxTime.getHHMMTime()}
            </b>
          </p>
        </Period>
      )}

      {isSupportingEndTimes && (
        <>
          <Timer
            label={translate("relay_start_hour")}
            timeChanged={endTimeChangedHandler}
            hour={endTime.hour}
            minute={endTime.minute}
            minTime={minTime.getIsoTime()}
            maxTime={maxTime.getIsoTime()}
          />
          {!endTime.isValid && (
            <Period>
              <p className="valid">
                {translate("choose_beetween")}:{" "}
                <b>
                  {minTime.getHHMMTime()} - {maxTime.getHHMMTime()}
                </b>
              </p>
            </Period>
          )}
        </>
      )}

      <Switch
        label="On"
        labelOff="Off"
        isOn={isOn}
        onChange={(value) => {
          setIsOn(value);
        }}
      />

      {isSupportingOnOffTimes && onTime && (
        <Timer
          label="ON"
          timeChanged={onTimeChangedHandler}
          hour={onTime.hour}
          minute={onTime.minute}
          minTime={minTimeOnOff.getIsoTime()}
          maxTime={maxTime.getIsoTime()}
        />
      )}
      {isSupportingOnOffTimes && onTime && !onTime.isValid && (
        <Period>
          <p className="valid">
            {translate("choose_beetween")}:{" "}
            <b>
              {minTimeOnOff.getHHMMTime()} - {maxTime.getHHMMTime()}
            </b>
          </p>
        </Period>
      )}

      {isSupportingOnOffTimes && offTime && (
        <Timer
          label="OFF"
          timeChanged={offTimeChangedHandler}
          hour={offTime.hour}
          minute={offTime.minute}
          minTime={minTimeOnOff.getIsoTime()}
          maxTime={maxTime.getIsoTime()}
        />
      )}
      {isSupportingOnOffTimes && offTime && !offTime.isValid && (
        <Period>
          <p className="valid">
            {translate("choose_beetween")}:{" "}
            <b>
              {minTimeOnOff.getHHMMTime()} - {maxTime.getHHMMTime()}
            </b>
          </p>
        </Period>
      )}
    </Modal>
  );
};

export default RelayEntrySettingModal;
