import styled, { css } from "styled-components";
import { size } from "./breakpoints";

const baseHeadings = css`
  color: ${(props) => props.colorFont || props.theme.color.heading};
  font-weight: ${(props) => props.weight || props.theme.font.weightH};
  font-family: ${(props) => props.fontFamily || props.theme.font.header};
  text-transform: ${(props) => props.textTransform};
  text-align: ${(props) => props.textAlign};
  padding-bottom: ${(props) => props.pb};
  padding-top: ${(props) => props.pt};
  padding-left: ${(props) => props.pl};
  padding-right: ${(props) => props.pr};
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  margin-left: ${(props) => props.ml || "0"};
  margin-right: ${(props) => props.mr || "0"};
`;

export const Text = styled.p`
  display: block;
  color: ${(props) => props.colorFont || props.theme.color.font};
  font-family: ${(props) => props.fontFamily || props.theme.font.main};
  text-transform: ${(props) => props.textTransform};
  font-weight: ${(props) => props.weight || props.theme.font.weight};
  font-size: ${(props) => props.xl || props.theme.font.size.p.xl};
  line-height: ${(props) => props.lineHeight || props.theme.font.lineH.p.xl};
  text-align: ${(props) => props.textAlign};
  padding-bottom: ${(props) => props.pb};
  padding-top: ${(props) => props.pt};
  padding-left: ${(props) => props.pl};
  padding-right: ${(props) => props.pr};
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  margin-left: ${(props) => props.ml || "0"};
  margin-right: ${(props) => props.mr || "0"};
  white-space: ${(props) => props.whiteSpace || "normal"};
`;

export const H6 = styled.h6`
  ${baseHeadings}
  font-size: ${(props) => props.xl || props.theme.font.size.h6.xl};
  line-height: ${(props) => props.lineHeight || props.theme.font.lineH.h6.xl};
`;

export const H5 = styled.h5`
  ${baseHeadings}
  font-size: ${(props) => props.xl || props.theme.font.size.h5.xl};
  line-height: ${(props) => props.lineHeight || props.theme.font.lineH.h5.xl};
`;

export const H3 = styled.h3`
  ${baseHeadings}
  font-size: 24;
  line-height: 24;
`;

export const Label = styled.p`
  color: ${(props) => props.colorFont || props.theme.color.sec5};
  font-family: ${(props) => props.fontFamily || props.theme.font.main};
  text-transform: ${(props) => props.textTransform || "uppercase"};
  font-weight: ${(props) => props.weight || props.theme.font.weightBold};
  letter-spacing: 1.8px;

  @media ${size.xl} {
    font-size: ${(props) => props.xl || props.theme.font.size.l.xl};
    line-height: ${(props) => props.lineHeight || 1.7};
  }

  @media ${size.l} {
    font-size: ${(props) => props.l || props.theme.font.size.l.l};
    line-height: ${(props) => props.lineHeight || 1.6};
  }

  @media ${size.md} {
    font-size: ${(props) => props.m || props.theme.font.size.l.m};
    line-height: ${(props) => props.lineHeight || 1.5};
  }

  @media ${size.sm} {
    font-size: ${(props) => props.s || props.theme.font.size.l.s};
    line-height: ${(props) => props.lineHeight || 1.5};
  }

  @media ${size.xs} {
    font-size: ${(props) => props.xs || props.theme.font.size.l.xs};
    line-height: ${(props) => props.lineHeight || 1.5};
  }
`;

// export const LabelBW = styled(Text)`
//   color: ${props => props.theme.color.grey6};
//   font-family: ${props => props.fontFamily || props.theme.font.main};
//   text-transform: ${props => props.textTransform || "uppercase"};
//   font-weight: 600;
//   letter-spacing: 0.4rem;
//   font-size: ${props => props.l || props.theme.font.size.l.xl};
//   line-height: 1.6;

//   ${down("lg")} {
//     font-size: ${props => props.l || props.theme.font.size.l.l};
//     line-height: ${props => props.lineHeight || 1.6};
//   }

//   ${down("md")} {
//     font-size: ${props => props.m || props.theme.font.size.l.m};
//     line-height: ${props => props.lineHeight || 1.5};
//   }

//   ${down("sm")} {
//     font-size: ${props => props.s || props.theme.font.size.l.s};
//     line-height: ${props => props.lineHeight || "1,5"};
//   }

//   ${down("xs")} {
//     font-size: ${props => props.xs || props.theme.font.size.l.xs};
//     line-height: ${props => props.lineHeight || "1,5"};
//   }
// `
