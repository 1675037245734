import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
import Button from "../Button/Button";
import CheckBox from "../Forms/checkbox";
import Dropdown from "../Forms/Dropdown";
import Input from "../Forms/Input";
import Switch from "../Switch/Switch";
import LanguageSelector from "../../layout/LeftSidePanel/LanguageSelector";
import CustomCheckbox from "../Forms/checkbox2";
//DATA

//COMPONENTS

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 2px 1fr;
  margin-bottom: 1.3rem;
`;

const Setting = styled.div`
  padding-right: 2rem;
  padding-left: 2rem;

  &.width {
    width: 400px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.sec6};
`;

const Name = styled.div`
  padding-right: 2rem;
`;

const Text = styled.p`
  max-height: 225px;
  overflow-y: auto;
`;

const PomieszczeniaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  align-items: center;
  justify-items: start;
`;

function LeftMenuSettings({
  children,
  name,
  text,
  dropdown,
  isMulti,
  label,
  label_for,
  label_text,
  handleChange,
  placeholder,
  selectedOption,
  isClearable,
  handleClear,
  className,
  defaultValue,
  input,
  checkbox,
  checked,
  onClick,
  language,
  onChange,
  inputType,
}) {
  return (
    <Wrapper>
      <Name>
        <p>{name}</p>
      </Name>
      <Line />
      <Setting>
        <Text>{text}</Text>
        {language ? <LanguageSelector label={false} /> : ""}
        {dropdown ? (
          <Dropdown
            dropdown={dropdown}
            isMulti={isMulti}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
        ) : (
          ""
        )}
        {input && (
          <Input
            //className="width400 white"
            value={input} // ensure this is the input prop
            onChange={onChange}
            maxLength={40}
            name={name} // usually it's better to use the name or a specific field identifier
            type={inputType || "text"}
          />
        )}
        {checkbox ? (
          <PomieszczeniaWrapper>
            {" "}
            {checkbox.map((pomieszczenie) => (
              <>
                <CustomCheckbox label_text={pomieszczenie} checked={checked} />
              </>
            ))}
            <Button>+ dodaj pokój</Button>
          </PomieszczeniaWrapper>
        ) : (
          ""
        )}
        {children}
      </Setting>
    </Wrapper>
  );
}

export default LeftMenuSettings;
