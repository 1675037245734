import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";

//DATA
//COMPONENTS
import RouteLayout from "../../layout/RouteLayout";
import TrybPracy from "./Termostat/TermostatWorkingMode";
import { FullCardWrapper, Heading, Loader } from "../../utils/utilsstyles";
import CircIcon from "../Icons/CircIcon";
import CircIconLabeled from "../Icons/CircIconLabeled";
import Switch from "../Switch/Switch";
import Slider from "../Slider/Slider";
import Dropdown from "../Forms/Dropdown";
import { loadDeviceDetails } from "../../services/ApiService";
// import SignalRService from "../../services/SignalRService";
import { useSignalR } from "../../services/SignalRProvider";
import { useNavigate } from "react-router-dom";

import Device from "../../services/Device";
import { useLanguage } from "../../services/LanguageContext";
import PrimaryDeviceSettings from "./PrimaryDeviceSettings";
import DiagnosticDeviceInformation from "./DiagnosticDeviceInformation";
import ActualSettings from "./ActualSettings";

const Wrapper = styled.div`
  z-index: 10;
  background-color: ${theme.color.secondLight};
`;

const AllSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 7fr;
  column-gap: 2rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6rem;
  row-gap: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
`;

const OneSetting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// const InfoLine = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   //height: 165px;
//   margin-top: 1.1rem;
//   margin-bottom: 0.2rem;
// `;

// export const IconValue = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-bottom: 0.8rem;
//   max-width: 100px;

//   h6 {
//     color: ${theme.color.second};
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 1;
//     margin-top: 5px;
//     text-transform: uppercase;
//     text-align: center;
//   }

//   h5 {
//     font-size: 18px;
//     font-weight: 500;
//     text-align: center;
//     text-transform: capitalize;
//   }
// `;

function TermostatSettings({ onClick, title, type }) {
  const { id } = useParams(); // Get the id from the URL
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);

  const { translate } = useLanguage();
  const signalRService = useSignalR();
  const navigate = useNavigate();

  // Fetch the initial device details
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      const fetchedDeviceDetails = await loadDeviceDetails(id); // Use the id from the URL
      console.log("Thermostat details", fetchedDeviceDetails);
      setDevice(new Device(fetchedDeviceDetails));
      setLoading(false);
    };

    fetchDeviceDetails();
  }, [id]);

  // Register to SignalRService when the component mounts and unregister when it unmounts
  useEffect(() => {
    const handleUpdateDeviceStatus = (updatedDevice) => {
      // console.log("Update recieved from SignalR");

      if (updatedDevice.id === id) {
        console.log("Update recieved from SignalR");
        setDevice(new Device(updatedDevice));
      }
    };

    signalRService.subscribe("UpdateDeviceStatus", handleUpdateDeviceStatus);

    return () => {
      signalRService.unsubscribe(
        "UpdateDeviceStatus",
        handleUpdateDeviceStatus
      );
    };
  }, [id]);

  const checkIfPropertyExists = (deviceToCheck, propertyToCheck) => {
    if (!deviceToCheck || !deviceToCheck.statusList) {
      return false; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    return deviceToCheck.statusList.some(
      (item) => item.code === propertyToCheck
    );
  };

  const getIconBasedOnRunMode = (runMode) => {
    let icon = "";

    switch (runMode) {
      case "manual":
        icon = "manual";
        break;
      case "program":
        icon = "harmonogram";
        break;
      case "holiday":
        icon = "holiday";
        break;
      case "Frost":
        icon = "frost";
        break;
      default:
        icon = "harmonogram";
        break;
    }

    return icon;
  };

  if (loading) {
    return (
      <Loader>
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color={theme.color.base1}
          secondaryColor={theme.color.base1}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Loader>
    ); // Or any other loading indicator
  }

  const upper_temp = "upper_temp";
  const lower_temp = "lower_temp";
  const backlight = "backlight";
  const internal_dead_zone = "internal_dead_zone";
  const external_dead_zone = "external_dead_zone";
  const temp_correction = "temp_correction";
  const floor_protect_temp = "floor_protect_temp";
  const ambient_level = "ambient_level";

  const max_cool_temp_set_f = "max_cool_temp_set_f";
  const min_cool_temp_set_f = "min_cool_temp_set_f";

  const warm_floor = "warm_floor";
  const relay_type = "relay_type";
  const valve_protection = "valve_protection";
  const mode = "mode";

  const control_algorithm = device.findExistingPropertyFromList([
    "control_algorithm",
    "control_type",
  ]);
  const max_heat_temp_set_f = device.findExistingPropertyFromList([
    "max_heat_temp_set_f",
    "max_floor_temp",
  ]);
  const min_heat_temp_set_f = device.findExistingPropertyFromList([
    "min_heat_temp_set_f",
    "min_floor_temp",
  ]);
  const pin_to_settings = device.findExistingPropertyFromList([
    "pin_to_setting",
    "pin_to_settings",
    "is_password_set",
  ]);

  const modbus_adr = "modbus_adr";
  const modbus_bauds = "modbus_bauds";
  const modbus_parity = "modbus_parity";
  const modbus_stopbits = "modbus_stopbits";
  const modbus_response_delay = "modbus_response_delay";
  const modbus_response_timeout = "modbus_response_timeout";
  const modbus_interframe_spacing = "modbus_interframe_spacing";

  const handleGoBack = () => {
    navigate(-1); // This navigates to the previous page in history
  };

  return (
    <Wrapper>
      <RouteLayout
        onClick={() => handleGoBack()}
        title={device.name}
        type={translate("deviceThermostat")}
      />
      <AllSettingsWrapper>
        <ActualSettings device={device} />
        <TrybPracy
          device={device}
          initialMode={device.getStatusValue("run_mode")}
        />

        <FullCardWrapper>
          <Heading>
            <h3>{translate("settings")}</h3>
          </Heading>

          <SettingsWrapper>
            {
              //Blokada klawiszy
            }
            {device.checkIfPropertyExists("child_lock") && (
              <OneSetting>
                <CircIconLabeled
                  name="block"
                  label={translate("thermostat_block")}
                />
                <Switch
                  isOn={device.getStatusValue("child_lock")}
                  onChange={(isOn) => {
                    device.setOnDeviceByParams("child_lock", isOn);
                  }}
                />
              </OneSetting>
            )}
            {
              //Dzwięk przycisków
            }
            {device.checkIfPropertyExists("key_sound") && (
              <OneSetting>
                <CircIconLabeled
                  name="sound"
                  label={translate("thermostat_sound")}
                />
                <Switch
                  isOn={device.getStatusValue("key_sound")}
                  onChange={(isOn) => {
                    device.setOnDeviceByParams("key_sound", isOn);
                  }}
                />
              </OneSetting>
            )}
            {
              //Pin do ustawień. pin_to_settings może mieć 3 wartości: pin_to_setting,pin_to_settings lub is_password_set
            }
            {device.checkIfPropertyExists(pin_to_settings) && (
              <OneSetting>
                <CircIconLabeled
                  name="choose"
                  label={translate("thermostat_settings_pin")}
                />
                <Switch
                  isOn={device.getStatusValue(pin_to_settings)}
                  onChange={(isOn) => {
                    device.setOnDeviceByParams(pin_to_settings, isOn);
                  }}
                />
              </OneSetting>
            )}

            {
              //Pin do odblokowania
            }
            {device.checkIfPropertyExists("pin_to_unlock") &&
              device.checkIfPropertyExists(pin_to_settings) &&
              device.getStatusValue(pin_to_settings) && (
                <OneSetting>
                  <CircIconLabeled
                    name="choose"
                    label={translate("thermostat_block_pin")}
                  />
                  <Switch
                    isOn={device.getStatusValue("pin_to_unlock")}
                    onChange={(isOn) => {
                      device.setOnDeviceByParams("pin_to_unlock", isOn);
                    }}
                  />
                </OneSetting>
              )}
            {
              //Ambient backlight
            }
            {
              device.checkIfPropertyExists("ambient_backlight") &&
                (typeof device.getStatusValue("ambient_backlight") ===
                "boolean" ? (
                  <OneSetting>
                    <CircIconLabeled
                      name="light"
                      label={translate("thermostat_ambient_backlight")}
                    />
                    <Switch
                      isOn={device.getStatusValue("ambient_backlight")}
                      onChange={(isOn) => {
                        device.setOnDeviceByParams("ambient_backlight", isOn);
                      }}
                    />
                  </OneSetting>
                ) : typeof device.getStatusValue("ambient_backlight") ===
                  "number" ? (
                  <Slider
                    label={translate("thermostat_ambient_backlight")}
                    icon="light"
                    value={device.getStatusValue("ambient_backlight")}
                    min={device.getFromRange("ambient_backlight")}
                    max={device.getToRange("ambient_backlight")}
                    step={device.getStepRange("ambient_backlight")}
                    onChange={(value) => {
                      console.log(
                        "ambient_backlight changed to value:" + value
                      );
                      device.setOnDeviceByParams("ambient_backlight", value);
                    }}
                    unit=" "
                  />
                ) : null) // Handle unexpected type case if needed
            }
            {
              //Ambient level
            }
            {device.checkIfPropertyExists("ambient_level") && (
              <Slider
                label={translate("thermostat_ambient_backlight")}
                icon="light"
                value={device.getStatusValue(ambient_level)}
                min={device.getFromRange(ambient_level)}
                max={device.getToRange(ambient_level)}
                step={device.getStepRange(ambient_level)}
                onChange={(value) => {
                  console.log("ambient_level changed to value:" + value);
                  device.setOnDeviceByParams(ambient_level, value);
                }}
                unit=" "
              />
            )}
            {
              //Jasność wyświetlacza
            }
            {device.checkIfPropertyExists(backlight) && (
              <Slider
                label={translate("thermostat_luminous")}
                icon="light2"
                value={device.getStatusValue(backlight)}
                min={device.getFromRange(backlight)}
                max={device.getToRange(backlight)}
                step={device.getStepRange(backlight)}
                onChange={(value) => {
                  console.log("Backlight changed to value:" + value);
                  device.setOnDeviceByParams(backlight, value);
                }}
                unit="%"
              />
            )}
            {
              //Max temperatura zadana
            }
            {device.checkIfPropertyExists(upper_temp) && (
              <Slider
                label={translate("thermostat_max_temp_set")}
                icon="tempmax"
                value={device.getStatusValueInTempScale(upper_temp)}
                min={device.getFromRangeInTempScale(upper_temp)}
                max={device.getToRangeInTempScale(upper_temp)}
                step={device.getStepRangeInTempScale(upper_temp)}
                onChange={(value) => {
                  console.log("Upper temp changed to value:" + value);
                  device.setOnDeviceByParamsInTempScale(upper_temp, value);
                }}
                unit="°C"
              />
            )}
            {
              //Min temperatura zadana
            }
            {device.checkIfPropertyExists(lower_temp) && (
              <Slider
                label={translate("thermostat_min_temp_set")}
                icon="tempmin"
                value={device.getStatusValueInTempScale(lower_temp)}
                min={device.getFromRangeInTempScale(lower_temp)}
                max={device.getToRangeInTempScale(lower_temp)}
                step={device.getStepRangeInTempScale(lower_temp)}
                onChange={(value) => {
                  console.log("Lower temp changed to value:" + value);
                  device.setOnDeviceByParamsInTempScale(lower_temp, value);
                }}
                unit="°C"
              />
            )}
            {
              //Wybór czujnika
            }
            {device.checkIfPropertyExists("sensor_choose") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("thermostat_sensor_type")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues("sensor_choose", translate)}
                  defaultValue={device.getSelectedEnum(
                    "sensor_choose",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "sensor_choose",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )}
            {
              //Histereza dla czujnika wewnętrznego
            }
            {device.checkIfPropertyExists(internal_dead_zone) && (
              <Slider
                label={translate("thermostat_hist_indoor")}
                icon="settings"
                value={device.getStatusValueInTempScale(internal_dead_zone)}
                min={device.getFromRangeInTempScale(internal_dead_zone)}
                max={device.getToRangeInTempScale(internal_dead_zone)}
                step={device.getStepRangeInTempScale(internal_dead_zone)}
                onChange={(value) => {
                  console.log(
                    "internal_dead_zone temp changed to value:" + value
                  );
                  device.setOnDeviceByParamsInTempScale(
                    internal_dead_zone,
                    value
                  );
                }}
                unit="°C"
              />
            )}
            {
              //Histereza dla czujnika zewnętrznego
            }
            {device.checkIfPropertyExists(external_dead_zone) && (
              <Slider
                label={translate("thermostat_hist_outdoor")}
                icon="settings"
                value={device.getStatusValueInTempScale(external_dead_zone)}
                min={device.getFromRangeInTempScale(external_dead_zone)}
                max={device.getToRangeInTempScale(external_dead_zone)}
                step={device.getStepRangeInTempScale(external_dead_zone)}
                onChange={(value) => {
                  console.log(
                    "external_dead_zone temp changed to value:" + value
                  );
                  device.setOnDeviceByParamsInTempScale(
                    external_dead_zone,
                    value
                  );
                }}
                unit="°C"
              />
            )}

            {
              // Min. temperatura podłogi w trybie grzania
            }
            {device.checkIfPropertyExists(min_heat_temp_set_f) &&
              (device.getSelectedEnum(mode, translate).value == "heat" ||
                device.getSelectedEnum(mode, translate).value == "hot" ||
                device.getSelectedEnum(mode, translate).value == "cold") && (
                <Slider
                  label={translate("thermostat_min_temp_floor")}
                  icon="tempmin"
                  value={device.getStatusValueInTempScale(min_heat_temp_set_f)}
                  min={device.getFromRangeInTempScale(min_heat_temp_set_f)}
                  max={device.getToRangeInTempScale(min_heat_temp_set_f)}
                  step={device.getStepRangeInTempScale(min_heat_temp_set_f)}
                  onChange={(value) => {
                    console.log(
                      "min_heat_temp_set_f temp changed to value:" + value
                    );
                    device.setOnDeviceByParamsInTempScale(
                      min_heat_temp_set_f,
                      value
                    );
                  }}
                  unit="°C"
                />
              )}

            {
              // Max temperatura podłogi w trybie grzania
            }
            {device.checkIfPropertyExists(floor_protect_temp) && (
              <Slider
                label={translate("thermostat_max_temp_floor")}
                icon="tempmax"
                value={device.getStatusValueInTempScale(floor_protect_temp)}
                min={device.getFromRangeInTempScale(floor_protect_temp)}
                max={device.getToRangeInTempScale(floor_protect_temp)}
                step={device.getStepRangeInTempScale(floor_protect_temp)}
                onChange={(value) => {
                  console.log(
                    "floor_protect_temp temp changed to value:" + value
                  );
                  device.setOnDeviceByParamsInTempScale(
                    floor_protect_temp,
                    value
                  );
                }}
                unit="°C"
              />
            )}
            {
              // Max temperatura podłogi w trybie grzania
            }
            {device.checkIfPropertyExists(max_heat_temp_set_f) &&
              (device.getSelectedEnum(mode, translate).value == "heat" ||
                device.getSelectedEnum(mode, translate).value == "hot" ||
                device.getSelectedEnum(mode, translate).value == "cold") && (
                <Slider
                  label={translate("thermostat_max_temp_floor")}
                  icon="tempmax"
                  value={device.getStatusValueInTempScale(max_heat_temp_set_f)}
                  min={device.getFromRangeInTempScale(max_heat_temp_set_f)}
                  max={device.getToRangeInTempScale(max_heat_temp_set_f)}
                  step={device.getStepRangeInTempScale(max_heat_temp_set_f)}
                  onChange={(value) => {
                    console.log(
                      "max_heat_temp_set_f temp changed to value:" + value
                    );
                    device.setOnDeviceByParamsInTempScale(
                      max_heat_temp_set_f,
                      value
                    );
                  }}
                  unit="°C"
                />
              )}

            {
              // Max temperatura podłogi w trybie chłodzenia
            }
            {device.checkIfPropertyExists(max_cool_temp_set_f) &&
              device.getSelectedEnum(mode, translate).value == "cool" && (
                <Slider
                  label={translate("thermostat_max_temp_floor_cool")}
                  icon="tempmax"
                  value={device.getStatusValueInTempScale(max_cool_temp_set_f)}
                  min={device.getFromRangeInTempScale(max_cool_temp_set_f)}
                  max={device.getToRangeInTempScale(max_cool_temp_set_f)}
                  step={device.getStepRangeInTempScale(max_cool_temp_set_f)}
                  onChange={(value) => {
                    console.log(
                      "max_cool_temp_set_f temp changed to value:" + value
                    );
                    device.setOnDeviceByParamsInTempScale(
                      max_cool_temp_set_f,
                      value
                    );
                  }}
                  unit="°C"
                />
              )}

            {
              // Min. temperatura podłogi w trybie chłodzenia
            }
            {device.checkIfPropertyExists(min_cool_temp_set_f) &&
              device.getSelectedEnum(mode, translate).value == "cool" && (
                <Slider
                  label={translate("thermostat_min_temp_floor_cool")}
                  icon="tempmin"
                  value={device.getStatusValueInTempScale(min_cool_temp_set_f)}
                  min={device.getFromRangeInTempScale(min_cool_temp_set_f)}
                  max={device.getToRangeInTempScale(min_cool_temp_set_f)}
                  step={device.getStepRangeInTempScale(min_cool_temp_set_f)}
                  onChange={(value) => {
                    console.log(
                      "min_cool_temp_set_f temp changed to value:" + value
                    );
                    device.setOnDeviceByParamsInTempScale(
                      min_cool_temp_set_f,
                      value
                    );
                  }}
                  unit="°C"
                />
              )}

            {
              // Korekta wyświetlania temperatury
            }
            {device.checkIfPropertyExists(temp_correction) && (
              <Slider
                label={translate("thermostat_correction_temp")}
                icon="settings"
                value={device.getStatusValueInTempScale(temp_correction)}
                min={device.getFromRangeInTempScale(temp_correction)}
                max={device.getToRangeInTempScale(temp_correction)}
                step={device.getStepRangeInTempScale(temp_correction)}
                onChange={(value) => {
                  console.log("temp_correction temp changed to value:" + value);
                  device.setOnDeviceByParamsInTempScale(temp_correction, value);
                }}
                unit="°C"
              />
            )}

            {
              //Algorytm sterowania
            }
            {device.checkIfPropertyExists("bolier_type") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("thermostat_algorithm")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues("bolier_type", translate)}
                  defaultValue={device.getSelectedEnum(
                    "bolier_type",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "bolier_type",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )}

            {
              //Algorytm sterowania - ONE
            }
            {device.checkIfPropertyExists(control_algorithm) && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("thermostat_algorithm")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues(control_algorithm, translate)}
                  defaultValue={device.getSelectedEnum(
                    control_algorithm,
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      control_algorithm,
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )}

            {
              //Komfortowa podłoga (lista)
            }
            {device.checkIfPropertyExists(warm_floor) && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("thermostat_comfort_floor")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues(warm_floor, translate)}
                  defaultValue={device.getSelectedEnum(warm_floor, translate)}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(warm_floor, selectedItem.value);
                  }}
                />
              </OneSetting>
            )}

            {
              // Typ przekaźnika (lista)
            }

            {device.checkIfPropertyExists(relay_type) && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("thermostat_relay_type")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues(relay_type, translate)}
                  defaultValue={device.getSelectedEnum(relay_type, translate)}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(relay_type, selectedItem.value);
                  }}
                />
              </OneSetting>
            )}
            {
              // Ochrona zaworów (switch)
            }
            {device.checkIfPropertyExists(valve_protection) && (
              <OneSetting>
                <CircIconLabeled
                  name="light"
                  label={translate("thermostat_protection")}
                />
                {typeof device.getStatusValue(valve_protection) ===
                "boolean" ? (
                  <Switch
                    isOn={device.getStatusValue(valve_protection)}
                    onChange={(isOn) => {
                      device.setOnDeviceByParams(valve_protection, isOn);
                    }}
                  />
                ) : (
                  <Dropdown
                    className="minWidth"
                    dropdown={device.getEnumValues(valve_protection, translate)}
                    defaultValue={device.getSelectedEnum(
                      valve_protection,
                      translate
                    )}
                    handleChange={(selectedItem) => {
                      device.setOnDeviceByParams(
                        valve_protection,
                        selectedItem.value
                      );
                    }}
                  />
                )}
              </OneSetting>
            )}
            {
              //Wybór systemu GRZANIE/CHŁODZENIE
            }
            {device.checkIfPropertyExists(mode) && (
              <OneSetting>
                <CircIconLabeled
                  name="choose"
                  label={translate("thermostat_system_choose")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues(mode, translate)}
                  defaultValue={device.getSelectedEnum(mode, translate)}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(mode, selectedItem.value);
                  }}
                />
              </OneSetting>
            )}
            {
              //Typ systemu ogrzewania
            }
            {device.checkIfPropertyExists("tpi_cycle") &&
              device.getSelectedEnum("bolier_type", translate).value ===
                "tpi" && (
                <OneSetting>
                  <CircIconLabeled
                    name="settings"
                    label={translate("thermostat_heating_system")}
                  />
                  <Dropdown
                    className="minWidth"
                    dropdown={device.getEnumValues("tpi_cycle", translate)}
                    defaultValue={device.getSelectedEnum(
                      "tpi_cycle",
                      translate
                    )}
                    handleChange={(selectedItem) => {
                      device.setOnDeviceByParams(
                        "tpi_cycle",
                        selectedItem.value
                      );
                    }}
                  />
                </OneSetting>
              )}

            {device.checkIfPropertyExists("span_deadzone") &&
              device.getSelectedEnum("bolier_type", translate).value ===
                "span" && (
                <OneSetting>
                  <CircIconLabeled
                    name="settings"
                    label={translate("bolier_type_span")}
                  />
                  <Dropdown
                    className="minWidth"
                    dropdown={device.getEnumValues("span_deadzone", translate)}
                    defaultValue={device.getSelectedEnum(
                      "span_deadzone",
                      translate
                    )}
                    handleChange={(selectedItem) => {
                      device.setOnDeviceByParams(
                        "span_deadzone",
                        selectedItem.value
                      );
                    }}
                  />
                </OneSetting>
              )}
          </SettingsWrapper>

          {device.checkIfPropertyExists(modbus_adr) && (
            <SettingsWrapper>
              <Heading>
                <hr></hr>
                <h3>{translate("modbus")}</h3>
              </Heading>
              {
                //Modbus Slave Adress
              }
              {device.checkIfPropertyExists(modbus_adr) && (
                <Slider
                  label={translate("modbus_slave_address")}
                  icon="light"
                  value={device.getStatusValue(modbus_adr)}
                  min={device.getFromRange(modbus_adr)}
                  max={device.getToRange(modbus_adr)}
                  step={device.getStepRange(modbus_adr)}
                  onChange={(value) => {
                    console.log("modbus_adr changed to value:" + value);
                    device.setOnDeviceByParams(modbus_adr, value);
                  }}
                  unit=" "
                />
              )}

              {
                //Modbus liczba bitów na sekundę
              }
              {device.checkIfPropertyExists(modbus_bauds) && (
                <OneSetting>
                  <CircIconLabeled
                    name="settings"
                    label={translate(modbus_bauds)}
                  />
                  <Dropdown
                    className="minWidth"
                    dropdown={device.getEnumValues(modbus_bauds, translate)}
                    defaultValue={device.getSelectedEnum(
                      modbus_bauds,
                      translate
                    )}
                    handleChange={(selectedItem) => {
                      device.setOnDeviceByParams(
                        modbus_bauds,
                        selectedItem.value
                      );
                    }}
                  />
                </OneSetting>
              )}

              {
                //Modbus parzystość
              }
              {device.checkIfPropertyExists(modbus_parity) && (
                <OneSetting>
                  <CircIconLabeled
                    name="settings"
                    label={translate(modbus_parity)}
                  />
                  <Dropdown
                    className="minWidth"
                    dropdown={device.getEnumValues(modbus_parity, translate)}
                    defaultValue={device.getSelectedEnum(
                      modbus_parity,
                      translate
                    )}
                    handleChange={(selectedItem) => {
                      device.setOnDeviceByParams(
                        modbus_parity,
                        selectedItem.value
                      );
                    }}
                  />
                </OneSetting>
              )}

              {
                //Modbus bity stopu
              }
              {device.checkIfPropertyExists(modbus_stopbits) && (
                <OneSetting>
                  <CircIconLabeled
                    name="settings"
                    label={translate(modbus_stopbits)}
                  />
                  <Dropdown
                    className="minWidth"
                    dropdown={device.getEnumValues(modbus_stopbits, translate)}
                    defaultValue={device.getSelectedEnum(
                      modbus_stopbits,
                      translate
                    )}
                    handleChange={(selectedItem) => {
                      device.setOnDeviceByParams(
                        modbus_stopbits,
                        selectedItem.value
                      );
                    }}
                  />
                </OneSetting>
              )}

              {
                //Modbus opóźnienie reakcji
              }
              {device.checkIfPropertyExists(modbus_response_delay) && (
                <Slider
                  label={translate("modbus_response_delay")}
                  icon="light"
                  value={device.getStatusValue(modbus_response_delay)}
                  min={device.getFromRange(modbus_response_delay)}
                  max={device.getToRange(modbus_response_delay)}
                  step={device.getStepRange(modbus_response_delay)}
                  onChange={(value) => {
                    console.log(
                      "modbus_response_delay changed to value:" + value
                    );
                    device.setOnDeviceByParams(modbus_response_delay, value);
                  }}
                  unit=" ms"
                />
              )}

              {
                //Modbus timeout reakcji
              }
              {device.checkIfPropertyExists(modbus_response_timeout) && (
                <Slider
                  label={translate("modbus_response_timeout")}
                  icon="light"
                  value={device.getStatusValueInTempScale(
                    modbus_response_timeout
                  )}
                  min={device.getFromRangeInTempScale(modbus_response_timeout)}
                  max={device.getToRangeInTempScale(modbus_response_timeout)}
                  step={device.getStepRangeInTempScale(modbus_response_timeout)}
                  onChange={(value) => {
                    console.log(
                      "modbus_response_timeout changed to value:" + value
                    );
                    device.setOnDeviceByParamsInTempScale(
                      modbus_response_timeout,
                      value
                    );
                  }}
                  unit=" s"
                />
              )}

              {
                //Modbus odstepy miedzyramkowe
              }
              {device.checkIfPropertyExists(modbus_interframe_spacing) && (
                <Slider
                  label={translate("modbus_interframe_spacing")}
                  icon="light"
                  value={device.getStatusValueInTempScale(
                    modbus_interframe_spacing
                  )}
                  min={device.getFromRangeInTempScale(
                    modbus_interframe_spacing
                  )}
                  max={device.getToRangeInTempScale(modbus_interframe_spacing)}
                  step={device.getStepRangeInTempScale(
                    modbus_interframe_spacing
                  )}
                  onChange={(value) => {
                    console.log(
                      "modbus_interframe_spacing changed to value:" + value
                    );
                    device.setOnDeviceByParamsInTempScale(
                      modbus_interframe_spacing,
                      value
                    );
                  }}
                  unit=" "
                />
              )}
            </SettingsWrapper>
          )}

          <PrimaryDeviceSettings device={device} name={device.name} />
          <DiagnosticDeviceInformation device={device} />
        </FullCardWrapper>
      </AllSettingsWrapper>
    </Wrapper>
  );
}

export default TermostatSettings;
