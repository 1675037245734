import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../utils/theme";
import { size } from "../utils/breakpoints";
//DATA
import { useLanguage } from "../services/LanguageContext";
//COMPONENTS
import Button from "../components/Button/Button";
import { Label } from "../utils/typo";

const FixedArea = styled.div`
  position: fixed;
  top: 0;
  z-index: 2;
  height: auto;
  width: auto;
  right: 0px;
  left: 200px;
  background-color: ${theme.color.sec7};
  padding: 2rem;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 65px;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const Heading = styled.h1`
  font-size: 32px;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    background-color: ${theme.color.base1};
    width: 27px;
    height: 6px;
    bottom: -3px;
  }
`;

const LayoutWrapper = styled.div`
  position: relative;
`;

const ChilderenWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  margin-top: 110px;
`;

function RouteLayout({ children, title, fixed, onClick, type }) {
  const { translate } = useLanguage();
  return (
    <LayoutWrapper>
      <FixedArea>
        <HeadingWrapper>
          <div>
            <Heading>{title}</Heading>
            {type ? <Label>{type}</Label> : ""}
          </div>
          {onClick ? (
            <Button href="/" variant="white">
              {translate("return")}
            </Button>
          ) : null}
        </HeadingWrapper>
        {fixed}
      </FixedArea>
      <ChilderenWrapper>{children}</ChilderenWrapper>
    </LayoutWrapper>
  );
}

export default RouteLayout;
