import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
//COMPONENTS
import Button from "../../components/Button/Button";
import LeftMenuSettings from "../../components/Settings/LeftMenuSettings";
import RouteLayout from "../../layout/RouteLayout";
import PrimaryDeviceSettings from "../../components/Settings/PrimaryDeviceSettings";
import { Heading } from "../../utils/utilsstyles";
//Functions
import { useLanguage } from "../../services/LanguageContext";
import Input from "../../components/Forms/Input";
import Modal from "../../components/Modals/Modal";
import CustomCheckbox from "../../components/Forms/checkbox2";

const checkbox = [
  "Salon",
  "Kuchnia",
  "Sypialnia",
  "Garaż",
  "Gabinet",
  "Łazienka",
];

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px;
  row-gap: 2rem;
  margin: 4rem 0;

  div {
    button {
      justify-content: center;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  margin-bottom: 3rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  row-gap: 6rem;
  column-gap: 6rem;
  align-items: flex-start;
  justify-items: stretch;
  justify-content: stretch;
  p {
    text-transform: capitalize;
  }
  button {
    width: 100%;
    justify-self: stretch;
    align-self: stretch;
    text-align: center;
    justify-content: center;
    padding: 20px;
    line-height: 1;
  }

  div {
    &.rooms {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 1rem;
    }
  }
`;

const ChangeError = styled.p`
  color: red;
  font-weight: 500;
`;

const Name = styled.div``;

const Elements = styled.div`
  margin-top: 130px;
`;

const MyHome = ({ title }) => {
  const { translate } = useLanguage();

  const [isModalRoomNameOpened, setIsModalRoomNameOpened] = useState(false);
  const toggleModalRoomName = () => {
    setIsModalRoomNameOpened(!isModalRoomNameOpened);
  };

  const [isModalAddRoomOpened, setIsModalAddRoomOpened] = useState(false);
  const toggleModalAddRoom = () => {
    setIsModalAddRoomOpened(!isModalAddRoomOpened);
  };

  return (
    <RouteLayout title={title} onClick="zapisz">
      <Elements>
        <Name>
          <Heading>
            <h3>{translate("room_name")}</h3>
          </Heading>
          <Wrapper>
            <Input value={translate("room_name")} readOnly={true} />
            <Button onClick={toggleModalRoomName}>
              {translate("change_room_name")}
            </Button>
          </Wrapper>
        </Name>
        <Heading>
          <h3>{translate("rooms")}</h3>
        </Heading>
        <Wrapper>
          <div className="rooms">
            {checkbox.map((room) => (
              <CustomCheckbox label_text={room} />
            ))}
          </div>

          <Button onClick={toggleModalAddRoom}>{translate("add_room")}</Button>
        </Wrapper>

        {/* <LeftMenuSettings
          name="Pomieszczenia"
          checkbox={[
            "Salon",
            "Kuchnia",
            "Sypialnia",
            "Garaż",
            "Gabinet",
            "Łazienka",
          ]}
        /> */}
        {/* <ButtonsWrapper>
          <Button variant="white">Dodaj dom</Button>
          <Button variant="white">Dołącz do domu</Button>
        </ButtonsWrapper> */}
      </Elements>
      {isModalRoomNameOpened && (
        <Modal
          title={translate("change_name")}
          onClose={toggleModalRoomName}
          onConfirm=""
          className="noHr"
        >
          <Input
            value="{customName}"
            maxLength={50}
            onChange="{(e) => {
                setCustomName(e.target.value);
              }}"
          />
          {/* {nameChangeError && (
              <ChangeError>{translate("change_name_error")}</ChangeError>
            )} */}
        </Modal>
      )}

      {isModalAddRoomOpened && (
        <Modal
          title={translate("adding_room")}
          onClose={toggleModalAddRoom}
          onConfirm=""
          confirmText={translate("add")}
          className="noHr"
        >
          <Input
            value="{customName}"
            maxLength={50}
            onChange="{(e) => {
                setCustomName(e.target.value);
              }}"
          />
          {/* {nameChangeError && (
              <ChangeError>{translate("change_name_error")}</ChangeError>
            )} */}
        </Modal>
      )}
    </RouteLayout>
  );
};

export default MyHome;
