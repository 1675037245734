import React, { useState } from "react";
//STYLES
import styled from "styled-components";

//FUNCTIONS
import { useLanguage } from "../../../../services/LanguageContext";
import {
  getThermostatsWithHolidayMode,
  copyHolidayToDevices,
} from "../../../../services/ApiService";

//COMPONENTS
import { Heading } from "../../../../utils/utilsstyles";
import Slider from "../../../Slider/Slider";
import Modal from "../../../Modals/Modal";
import Button from "../../../Button/Button";
import CustomCheckbox from "../../../Forms/checkbox2";

const Wrapper = styled.div`
  h3 {
    margin-bottom: 5px;
  }
`;

const SettingsWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

function HolidayMode({ device }) {
  const holiday_temp_set = "holiday_temp_set";
  const holiday_days_set = "holiday_days_set";
  const [isModalDeviceOpen, setIsModalDeviceOpen] = useState(false);

  const [sameTypeDevices, setSameTypeDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isAnyDeviceSelected, setIsAnyDeviceSelected] = useState(false);

  const { translate } = useLanguage();

  const toggleModalDevice = async () => {
    if (!isModalDeviceOpen) {
      const devices = await getThermostatsWithHolidayMode(device.id);
      setSameTypeDevices(devices);
      setSelectedDevices([]);
      setIsAnyDeviceSelected(false);
    }
    setIsModalDeviceOpen(!isModalDeviceOpen);
  };

  const toggleChecked = (deviceId) => {
    setSelectedDevices((prevSelectedDevices) => {
      if (prevSelectedDevices.includes(deviceId)) {
        // If the device is already selected, remove it
        setIsAnyDeviceSelected(
          prevSelectedDevices.filter((id) => id !== deviceId).length > 0
        );
        return prevSelectedDevices.filter((id) => id !== deviceId);
      } else {
        // Otherwise, add it to the selection
        setIsAnyDeviceSelected(true);
        return [...prevSelectedDevices, deviceId];
      }
    });
  };

  const handleCopyToDevices = async () => {
    await copyHolidayToDevices(
      device.id,
      device.getStatusValue(holiday_temp_set),
      device.getStatusValue(holiday_days_set),
      selectedDevices
    );

    toggleModalDevice();
  };

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("holiday_mode_settings")}</h3>
      </Heading>
      <SettingsWrapper>
        <Slider
          icon="temperature"
          label={translate("holiday_mode_temperature")}
          value={device.getStatusValueInTempScale(holiday_temp_set)}
          min={device.getFromRangeInTempScale(holiday_temp_set)}
          max={device.getToRangeInTempScale(holiday_temp_set)}
          step={device.getStepRangeInTempScale(holiday_temp_set)}
          onChange={(value) => {
            console.log("holiday_temp_set changed to value:" + value);
            device.setOnDeviceByParamsInTempScale(holiday_temp_set, value);
          }}
        />
        <Slider
          icon="temperature"
          label={translate("holiday_mode_duration")}
          unit={translate("days")}
          value={device.getStatusValue(holiday_days_set)}
          min={device.getFromRange(holiday_days_set)}
          max={device.getToRange(holiday_days_set)}
          step={device.getStepRange(holiday_days_set)}
          onChange={(value) => {
            console.log("holiday_days_set changed to value:" + value);
            device.setOnDeviceByParams(holiday_days_set, value);
          }}
        />
      </SettingsWrapper>

      {isModalDeviceOpen && (
        <Modal
          title={translate("copy_settings_another_device")}
          intro={translate("intro_choose_coppy_device")}
          onClose={toggleModalDevice}
          isValid={isAnyDeviceSelected}
          onConfirm={() => handleCopyToDevices()}
        >
          {sameTypeDevices.map((item) => (
            <CustomCheckbox
              key={item.id}
              label_text={item.name}
              checked={selectedDevices.includes(item.id)}
              onChange={() => toggleChecked(item.id)}
            />
          ))}
        </Modal>
      )}

      <Button variant="white" onClick={toggleModalDevice}>
        {translate("copy_settings_another_device")}
      </Button>
    </Wrapper>
  );
}

export default HolidayMode;
