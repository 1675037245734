import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { motion, AnimatePresence } from "framer-motion";
import CardFastSettingsWrapper from "./CardFastSettingsWrapper";
import { Ikonka } from "../../../Icons/Icon";
import { useLanguage } from "../../../../services/LanguageContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  position: relative;
  height: auto;
  min-height: 220px;
  width: 100%;
  padding: 1.5rem;
  color: ${theme.color.sec3};

  background-color: ${theme.color.sec9};
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1),
    -4px -4px 8px 0 rgba(255, 255, 255, 0.5);

  hr {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: ${theme.color.sec3};
    padding: 0.5rem;
    margin: 0;
    margin-bottom: -1rem;

    > svg {
      fill: ${theme.color.sec3};
      background-color: transparent;
      height: 12px;
      width: auto;
    }

    &:hover {
      background-color: ${theme.color.sec7};
      border-radius: 4px;

      > svg {
        fill: ${theme.color.sec3};
        background-color: transparent;
      }
    }
  }
`;

const ExpandButton = styled.div`
  background-color: ${theme.color.base1};
  position: absolute;
  width: 60px;
  height: 30px;
  right: 0;
  bottom: 0;
  border-radius: 25px 0px 10px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  > svg {
    fill: ${theme.color.sec9};
    background-color: transparent;
    height: 12px;
    width: auto;
    margin-left: 4px;
  }

  &:hover {
    background-color: ${theme.color.sec6};

    > svg {
      fill: ${theme.color.sec9};
      background-color: transparent;
    }
  }
`;

const CardWrapper = ({ isOnline, children, settings, onOpening }) => {
  const [clicked, setClicked] = useState(false);
  const { translate } = useLanguage();

  const handleClick = () => {
    if (!clicked && onOpening) {
      onOpening();
    }
    setClicked(!clicked);
  };

  return (
    <Wrapper>
      {children}
      {isOnline ? (
        <>
          <AnimatePresence initial={false}>
            {clicked && (
              <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {settings}
              </motion.section>
            )}
          </AnimatePresence>
          {settings.props.typ !== "Przekaźnik" &&
          settings.props.typ !== "Inny" ? (
            <>
              {/* <hr />
              <button onClick={handleClick}>
                {clicked ? translate("collapse") : translate("more")}
                <Ikonka name={clicked ? "up" : "down"} />
              </button> */}
              <ExpandButton onClick={handleClick}>
                <Ikonka name={clicked ? "up" : "down"} />
              </ExpandButton>
            </>
          ) : null}
        </>
      ) : null}
    </Wrapper>
  );
};

export default CardWrapper;
