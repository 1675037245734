import React from "react";
// STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
// COMPONENTS
import { Ikonka } from "../../assets/icons/icons";
import CircIcon from "./CircIcon";

const Wrapper = styled.div`
  height: 40px;
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 1rem;
  justify-content: stretch;
  align-content: stretch;
  justify-items: stretch;
  align-items: center;

  p {
    font-size: 14px;
    opacity: 0.7;
  }

  p:nth-of-type(2) {
    opacity: 1;
    font-weight: 600;
    font-size: 16px;
    //text-transform: capitalize;
  }
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlackLabel = styled.p`
  font-size: 15px;
  color: ${theme.color.font};
  font-weight: 500;

  &.active {
    color: ${theme.color.base1};
    font-weight: 700;
  }
`;

function CircIconLabeled({ name, value, label, className }) {
  return (
    <Wrapper>
      <CircIcon name={name} className={className} />
      <Labels>
        <BlackLabel className={className}>{label}</BlackLabel>
        {/* <p>{label}</p> */}
        <p>{value}</p>
      </Labels>
    </Wrapper>
  );
}

export default CircIconLabeled;
