import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";

//DATA
//COMPONENTS
import { FullCardWrapper, Heading } from "../../../utils/utilsstyles";
import ButtonRounded from "../../Button/ButtonRounded";
import HarmonogramMode from "./WorkingModes/HarmonogramMode";
import ManualMode from "./WorkingModes/ManualMode";
import HolidayMode from "./WorkingModes/HolidayMode";
import FrostMode from "./WorkingModes/FrostMode";
import PartyMode from "./WorkingModes/PartyMode";
import Modal from "../../Modals/Modal";
//FUNCTIONS
import { setOnDevice } from "../../../services/ApiService";
import { useLanguage } from "../../../services/LanguageContext";

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Line = styled.hr`
  margin-top: 1.3rem !important;
  margin-bottom: 1.3rem !important;
`;

function TermostatWorkingMode({ children, device, initialMode }) {
  const [activeMode, setActiveMode] = useState("program");
  const [currentMode, setCurrentMode] = useState(initialMode);
  const { translate } = useLanguage();

  useEffect(() => {
    if (device) {
      setActiveMode(device.getRunModeByValue(initialMode));
    }
  }, [device]);

  const handleSetActiveMode = (mode) => {
    device.setOnDeviceByParams("run_mode", device.getRunMode(mode));
    // setOnDevice({
    //   deviceId: device.id,
    //   command: "run_mode",
    //   value: device.getRunMode(mode),
    // });
    setActiveMode(mode);
  };

  return (
    <FullCardWrapper>
      <Heading>
        <h3>{translate("working_mode")}</h3>
        <p>{translate("choose_working_mode")}</p>
      </Heading>
      <ButtonsWrapper>
        {device.containsRunMode("program") && (
          <ButtonRounded
            label={translate("run_mode_program")}
            icon="harmonogram"
            active={activeMode === "program"}
            onClick={() => handleSetActiveMode("program")}
          />
        )}

        {device.containsRunMode("manual") && (
          <ButtonRounded
            label={translate("run_mode_manual")}
            icon="manual"
            active={activeMode === "manual"}
            onClick={() => handleSetActiveMode("manual")}
          />
        )}

        {device.containsRunMode("holiday") && (
          <ButtonRounded
            label={translate("run_mode_holiday")}
            icon="holiday"
            active={activeMode === "holiday"}
            onClick={() => handleSetActiveMode("holiday")}
          />
        )}

        {device.containsRunMode("boost") && (
          <ButtonRounded
            label={translate("run_mode_boost")}
            icon="party" // zmienic ikonke
            active={activeMode === "boost"}
            onClick={() => handleSetActiveMode("boost")}
          />
        )}

        {device.containsRunMode("frost") && (
          <ButtonRounded
            label={translate("run_mode_Frost")}
            icon="frost"
            active={activeMode === "frost"}
            onClick={() => handleSetActiveMode("frost")}
          />
        )}
      </ButtonsWrapper>

      <Line />

      {activeMode === "program" && <HarmonogramMode device={device} />}
      {activeMode === "manual" && <ManualMode device={device} />}
      {activeMode === "holiday" && <HolidayMode device={device} />}
      {activeMode === "frost" && <FrostMode device={device} />}
      {activeMode === "boost" && <PartyMode device={device} />}
    </FullCardWrapper>
  );
}

export default TermostatWorkingMode;
