import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
import { Label } from "../../utils/utilsstyles";
import { theme } from "../../utils/theme";
import { breakpoint } from "styled-components-breakpoint";
//DATA
import engo_logo from "./../../assets/engo_logo.png";
//COMPONENTS
import Button from "../../components/Button/Button";
import Input from "../../components/Forms/Input";

import { useAuth } from "../../services/AuthContext";
import axios from "axios";
import Cookies from "js-cookie";
import CheckBox from "../../components/Forms/checkbox";
import Dropdown from "../../components/Forms/Dropdown";
import { loginUser } from "../../services/ApiService";
import LanguageSelector from "../../layout/LeftSidePanel/LanguageSelector";
import { useLanguage } from "../../services/LanguageContext";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;
const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 5rem;

  h3 {
    margin-bottom: 2rem;
  }

  p {
    ${breakpoint("md")`
        font-size: 10px;
    `}
  }
`;

const InputWrapper = styled.div`
  display: grid;
  row-gap: 1.5rem;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;

const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: rgb(149, 193, 31);
  background: linear-gradient(
    146deg,
    rgba(149, 193, 31, 1) 0%,
    rgba(87, 87, 86, 1) 100%
  );
  height: 100vh;
  padding: 5rem;

  ${Label} {
    background-color: blue;
    //color: #1eff00;
  }
  p,
  h2 {
    color: white;
  }
  p {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const Logo = styled.img`
  width: 30%;
  margin-bottom: 5rem;
  justify-self: flex-start;
`;

const LoginError = styled.p`
  color: red;
  font-weight: 500;
`;

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loginErrorCheckbox, setLoginErrorCheckbox] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { login, logout } = useAuth();
  const { translate } = useLanguage();

  const handleSubmit = async (e) => {
    if (!isCheckboxChecked) {
      setLoginErrorCheckbox(true);
      return;
    }

    e.preventDefault(); // Prevent form submission from reloading the page

    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await loginUser(data); // Call your context's login method

      if (response.status == 200) {
        setLoginError(false);
        login(response.data.token);
      } else if (response.status == 401) {
        setLoginError(true);
      } else {
        logout();
        setLoginError(false);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoginError(true);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(); // Assuming handleSubmit is your function to trigger on button click
    }
  };

  // Step 2: Use useEffect to attach the event listener when the component mounts
  useEffect(() => {
    // Attach the event listener
    window.addEventListener("keypress", handleKeyPress);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <Layout>
      <LoginWrapper>
        <Logo src={engo_logo} alt="logo" />

        <h3>{translate("loginPage", "Logging in")}</h3>
        <p>
          {translate(
            "loginWelcomeToEngoControls",
            "Welcome to the Engo Controls dashboard."
          )}
          <br />
          {translate("loginLoginToSeeDevices", "Log in to view your devices.")}
        </p>
        <InputWrapper>
          <LanguageSelector />
          <Input
            label_for="email"
            label_text={translate("loginEmail", "Email")}
            type="email"
            value={username}
            id="Email"
            name="naemailme"
            maxlength="40"
            onChange={handleUsernameChange}
          />

          <Input
            label_for="password"
            label_text={translate("loginPassword", "Password")}
            type="password"
            value={password}
            id="password"
            name="password"
            maxlength="40"
            onChange={handlePasswordChange}
          />

          {loginError && (
            <LoginError>
              {translate(
                "loginInvalidLoginPassword",
                "Invalid login or password"
              )}
            </LoginError>
          )}
          {isCheckboxChecked
            ? ""
            : loginErrorCheckbox && (
                <LoginError>
                  {translate(
                    "loginErrorMissingAgreementToTerms",
                    "You must give consent to log in to the application."
                  )}
                </LoginError>
              )}

          <CheckBox
            id="zgody"
            className={
              loginErrorCheckbox ? (isCheckboxChecked ? "" : "error") : ""
            }
            label_text={translate(
              "loginAgreeToTerms",
              "*I agree to the Privacy Policy and the Service Agreement"
            )}
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          />

          <Button
            variant={isCheckboxChecked ? "base" : "white"}
            onClick={handleSubmit}
            disabled={!isCheckboxChecked}
          >
            {translate("loginLogin", "Log in")}
          </Button>
        </InputWrapper>
      </LoginWrapper>

      <SideWrapper>
        <h2 colorFont="white">{translate("loginWelcome", "Hello!")}</h2>
        <p>
          {translate(
            "loginInvitationToUseEngoControls",
            "We invite you to use our Engo Controls application."
          )}
        </p>
      </SideWrapper>
    </Layout>
  );
}

export default Login;
