import React from "react";
// STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
// COMPONENTS
import { Ikonka } from "../../../Icons/Icon";
import { Tag } from "../../../../utils/utilsstyles";

const Wrapper = styled.div`
  position: absolute;
  width: 60px;
  height: auto;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  > a {
    position: absolute;
    top: 90px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    border-left: 1px solid ${theme.color.base1};
    border-top: 1px solid ${theme.color.base1};
    border-bottom: 1px solid ${theme.color.base1};
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
    cursor: pointer;
    background-color: ${theme.color.base1};
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${theme.color.prim1};
      svg {
        fill: transparent;
      }
    }
  }

  svg {
    fill: transparent;
    height: 40px;
  }

  a.off {
    background-color: ${theme.color.sec5};
    border-left: 1px solid ${theme.color.sec5};
    border-top: 1px solid ${theme.color.sec5};
    border-bottom: 1px solid ${theme.color.sec5};
    &:hover {
      background-color: ${theme.color.prim1};
    }
    svg {
      fill: transparent;

      &:hover {
        fill: transparent;
      }
    }
  }
`;

function CardOnOffButton({ onClick, status }) {
  return (
    <Wrapper>
      <a onClick={onClick} className={status ? "" : "off"}>
        <Ikonka name="powerbutton" />
      </a>
    </Wrapper>
  );
}

export default CardOnOffButton;
