const breakpoint= {
    xs: "320px",
    sm: "576px",
    md: "980px",
    l: "1320px",
    xl: "1921px",
  };

export const size = {
    xs: `(max-width: ${breakpoint.xs})`,
    sm: `(max-width: ${breakpoint.sm})`,
    md: `(max-width: ${breakpoint.md})`,
    l: `(max-width: ${breakpoint.l})`,
    xl: `(max-width: ${breakpoint.xl})`,
};
