import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";

export const SettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
  height: auto;
  padding: 1rem 0;
`;

function CardFastSettingsWrapper({ children, typ }) {
  return <SettingsWrapper typ={typ}>{children}</SettingsWrapper>;
}

export default CardFastSettingsWrapper;
