import React, { useState } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { NavLink } from "react-router-dom";
//DATA
import engo_logo from "../../assets/engo_logo.png";

import { useAuth } from "../../services/AuthContext";
import { Ikonka } from "../../components/Icons/Icon";
import { useLanguage } from "../../services/LanguageContext";
import LanguageSelector from "./LanguageSelector";

//COMPONENTS

const data = [
  {
    id: "1",
    id_name: "overview",
    name: "Przegląd",
    active: true,
    link: "/",
  },
  // {
  //   id: "2",
  //   id_name: "myHome",
  //   name: "Mój dom",
  //   active: false,
  //   link: "/myhome",
  // },
  // {
  //   id: "3",
  //   //id_name: "messages",
  //   name: "Utils",
  //   active: false,
  //   link: "/messages",
  // },
  // {
  //   id: "4",
  //   id_name: "settings",
  //   name: "Ustawienia",
  //   active: false,
  //   link: "/settings",
  // },
];

const footer = [
  // {
  //   id: "1",
  //   id_name: "user",
  //   name: "Użytkownik",
  //   active: false,
  // },
  {
    id: "2",
    id_name: "logout",
    name: "Wyloguj",
    active: false,
  },
];

const NavbarWrapper = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 200px;
  background-color: ${theme.color.sec9};
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0px;
`;

const Logo = styled.img`
  width: 100%;
  margin: 2rem 0;
  padding: 0 1rem;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const Label = styled.h6`
  color: ${theme.color.sec3};
  font-size: 16px;
  font-weight: 500;
`;

const NavItem = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  height: 36px;
  border-radius: 0px 20px 20px 0;
  margin: 0.7rem 0;
  margin-right: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  svg {
    fill: ${theme.color.sec3};
    width: 22px;
    margin-right: 1rem;
    transition: all 0.4s ease-in-out;
  }

  &.active {
    background-color: ${theme.color.base1};
    background: linear-gradient(
      90deg,
      rgba(149, 193, 31, 1) 0%,
      rgba(87, 87, 86, 1) 100%
    );

    ${Label} {
      color: white;
    }

    svg {
      fill: white;
    }
  }
  &:hover {
    background-color: ${theme.color.sec6};

    ${Label} {
      color: ${theme.color.sec3};
    }
    svg {
      fill: ${theme.color.sec3};
    }
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Lang = styled.div`
  margin: 1rem;
`;

function LeftSidePanel({ onClick }) {
  const { logout } = useAuth();
  const [activeId, setActiveId] = useState(data.find((item) => item.active).id);
  const { translate } = useLanguage();

  const handleClick = (navitem) => {
    setActiveId(navitem.id); // Update the active ID on click
  };

  const handleClickFooter = (navitem) => {
    if (navitem.id === "2") {
      logout();
    }
  };

  return (
    <NavbarWrapper>
      <MenuWrapper>
        <Logo src={engo_logo} alt="logo" />
        {data.map((navitem) => (
          <NavLink
            to={navitem.link}
            key={navitem.id}
            className={navitem.id === activeId ? "active" : ""}
            onClick={() => handleClick(navitem)}
          >
            <NavItem className={navitem.id === activeId ? "active" : ""}>
              <Ikonka name={navitem.id_name} />
              <Label>{translate(navitem.id_name, navitem.name)}</Label>
            </NavItem>
          </NavLink>
        ))}
        <Lang>
          <LanguageSelector />
        </Lang>
      </MenuWrapper>

      <FooterWrapper>
        {footer.map((navitem) => {
          return (
            <NavItem
              key={navitem.id}
              className={navitem.active ? "active" : ""}
              onClick={() => handleClickFooter(navitem)}
            >
              <Ikonka name={navitem.id_name} />

              <Label>{translate(navitem.id_name, navitem.name)}</Label>
            </NavItem>
          );
        })}
      </FooterWrapper>
    </NavbarWrapper>
  );
}

export default LeftSidePanel;
