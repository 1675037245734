import React, { useEffect, useState } from "react";
import Dropdown from "../../components/Forms/Dropdown";
import { useLanguage } from "../../services/LanguageContext";
// STYLES
import styled from "styled-components";

const LangWrapper = styled.div`
  //padding: 1rem;
`;

const LanguageSelector = ({ label }) => {
  const { changeLanguage, availableLanguages, selectedLanguage, translate } =
    useLanguage();

  const dropdownLanguages =
    availableLanguages && availableLanguages.length > 0
      ? availableLanguages.map((lang) => ({
          value: lang.languageKey,
          label: lang.languageDescription,
        }))
      : [];

  const selectedLanguageOption =
    availableLanguages && availableLanguages.length > 0
      ? dropdownLanguages.find((option) => option.value === selectedLanguage)
      : null;

  return (
    <LangWrapper>
      {/* <ButtonsWrapper>
        <button onClick={() => changeLanguage("en")}>EN</button>
        <button onClick={() => changeLanguage("pl")}>PL</button>
        <button onClick={() => changeLanguage("de")}>DE</button>
      </ButtonsWrapper> */}

      <Dropdown
        className={"width100"}
        label={label}
        dropdown={dropdownLanguages}
        defaultValue={selectedLanguageOption}
        handleChange={(selectedValue) => {
          console.log("selected value: " + selectedValue);
          changeLanguage(selectedValue.value);
        }}
        label_text={translate("language", "Language")}
        isMulti={false}
        isClearable={false}
        placeholder={
          selectedLanguageOption == null ? "" : selectedLanguageOption.label
        }
      />
    </LangWrapper>
  );
};

export default LanguageSelector;
