import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
import Button from "../Button/Button";
import CheckBox from "../Forms/checkbox";
import Dropdown from "../Forms/Dropdown";
import Input from "../Forms/Input";
import Switch from "../Switch/Switch";
import LanguageSelector from "../../layout/LeftSidePanel/LanguageSelector";
import { Heading } from "../../utils/utilsstyles";
import Modal from "../Modals/Modal";
//DATA
import { useLanguage } from "../../services/LanguageContext";
import {
  setDeviceName,
  getRoomsForHome,
  setRoomToDevice,
} from "../../services/ApiService";
//COMPONENTS

const Wrapper = styled.div`
  margin-bottom: 1.3rem;
  display: grid;
  grid-template-columns: 100px 1fr auto;
  row-gap: 2rem;
  column-gap: 1rem;
  align-items: center;
  justify-items: stretch;
  justify-content: stretch;
  p {
    text-transform: capitalize;
  }
  button {
    width: 100%;
    justify-self: stretch;
    align-self: stretch;
    text-align: center;
    justify-content: center;
    padding: 20px;
    line-height: 1;
  }
`;

const ChangeError = styled.p`
  color: red;
  font-weight: 500;
`;

function PrimaryDeviceSettings({ name, device }) {
  const [customName, setCustomName] = useState(name);
  const [isModalNameOpened, setIsModalNameOpened] = useState(false);
  const [nameChangeError, setNameChangeError] = useState(false);

  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isModalRoomOpened, setIsModalRoomOpened] = useState(false);
  const [rooms, setRooms] = useState(null);
  const [modalSelectedRoom, setModalSelectedRoom] = useState(null);
  const { translate } = useLanguage();

  useEffect(() => {
    if (device.rooms.length > 0) {
      setSelectedRoom({
        value: device.rooms[0].room_id,
        label: device.rooms[0].name,
      });
    } else {
      setSelectedRoom(null);
    }
  }, [device.rooms]);

  const handleConfirmNameChange = async () => {
    // Replace your existing API call accordingly
    try {
      const result = await setDeviceName(device.id, customName);

      if (result) {
        setIsModalNameOpened(false);
        setNameChangeError(false);
      } else {
        setNameChangeError(true);
      }
    } catch (error) {
      setNameChangeError(true);
    }
  };

  const toggleModal = () => {
    if (!isModalNameOpened) {
      setNameChangeError(false);
      setCustomName(device.name);
    }

    setIsModalNameOpened(!isModalNameOpened);
  };

  const toggleModalRoom = async () => {
    if (!isModalRoomOpened) {
      const roomsResponse = await getRoomsForHome(device.homeId);
      const roomsMap = roomsResponse.map((item) => ({
        value: item.room_Id,
        label: item.name,
      }));

      setRooms(roomsMap);
      setModalSelectedRoom(selectedRoom);
      setIsModalRoomOpened(!isModalRoomOpened);
    } else {
      setIsModalRoomOpened(!isModalRoomOpened);
    }
  };

  const handleConfirmRoomChange = async () => {
    // Replace your existing API call accordingly
    try {
      const result = await setRoomToDevice(
        device.id,
        device.homeId,
        modalSelectedRoom !== null ? modalSelectedRoom.value : null
      );

      if (result.status == 200) {
        setIsModalRoomOpened(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <Heading>
        <h3>{translate("settings")}</h3>
      </Heading>
      <Wrapper>
        {
          // Ustawienie lokalizacji urzadzenia
        }
        <p>{translate("room")}</p>
        {/* <Dropdown
          dropdown={device.rooms.map((item) => ({
            value: item.room_id,
            label: item.name,
          }))}
          placeholder={translate("device_no_room_selected")}
          defaultValue={selectedRoom}
          readOnly={true}
        />{" "} */}

        <Input
          value={
            device.rooms[0] === undefined
              ? translate("device_no_room_selected")
              : device.rooms[0].name
          }
          readOnly={true}
        />
        <Button onClick={toggleModalRoom}>
          {translate("device_select_room")}
        </Button>
        {isModalRoomOpened && (
          <Modal
            title={translate("device_select_room")}
            onClose={toggleModalRoom}
            onConfirm={handleConfirmRoomChange}
            className="noHr"
          >
            <Dropdown
              dropdown={rooms}
              placeholder={translate("device_no_room_selected")}
              defaultValue={selectedRoom}
              readOnly={false}
              isClearable={true}
              handleChange={(selectedItem) => {
                setModalSelectedRoom(selectedItem);
              }}
            />
          </Modal>
        )}
        {
          // Ustawienie nazwy urzadzenia
        }
        <p>{translate("name")}</p>
        <Input value={device.name} readOnly={true} />
        <Button onClick={toggleModal}>{translate("change_name")}</Button>
        {isModalNameOpened && (
          <Modal
            title={translate("change_name")}
            onClose={toggleModal}
            onConfirm={handleConfirmNameChange}
            className="noHr"
          >
            <Input
              value={customName}
              maxLength={50}
              onChange={(e) => {
                setCustomName(e.target.value);
              }}
            />
            {nameChangeError && (
              <ChangeError>{translate("change_name_error")}</ChangeError>
            )}
          </Modal>
        )}
      </Wrapper>
    </>
  );
}

export default PrimaryDeviceSettings;
