import React from "react";
//STYLES
import styled from "styled-components";

//DATA
import { Heading } from "../../../../utils/utilsstyles";
import Slider from "../../../Slider/Slider";
//COMPONENTS
//FUNCTIONS
import { useLanguage } from "../../../../services/LanguageContext";

const Wrapper = styled.div`
  h3 {
    margin-bottom: 5px;
  }
`;

const SettingsWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

function PartyMode({ device }) {
  const { translate } = useLanguage();

  const party_time = "party_time";
  const party = "party";

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("party_header")}</h3>
      </Heading>
      <SettingsWrapper>
        <Slider
          icon="time"
          label={translate("party_time")}
          value={device.getStatusValue(party_time)}
          min={device.getFromRange(party_time)}
          max={device.getToRange(party_time)}
          step={device.getStepRange(party_time)}
          onChange={(value) => {
            console.log("party_time changed to value:" + value);
            device.setOnDeviceByParams(party_time, value);
          }}
          unit=" H"
        />
        <Slider
          icon="temperature"
          label={translate("party_temperature")}
          value={device.getStatusValueInTempScale(party)}
          min={device.getFromRangeInTempScale(party)}
          max={device.getToRangeInTempScale(party)}
          step={device.getStepRangeInTempScale(party)}
          onChange={(value) => {
            console.log("party changed to value:" + value);
            device.setOnDeviceByParamsInTempScale(party, value);
          }}
        />
      </SettingsWrapper>
    </Wrapper>
  );
}

export default PartyMode;
