import TimeService from "../TimeService";

class HourMinute {
  constructor(hour, minute, isValid = true) {
    this.hour = hour;
    this.minute = minute;
    this._isValid = isValid;
  }

  // Getter for hour
  get hour() {
    return this._hour;
  }

  // Getter for minute
  get minute() {
    return this._minute;
  }

  // Setter for hour
  set hour(value) {
    this._hour = value;
  }

  // Setter for minute
  set minute(value) {
    this._minute = value;
  }

  get isValid() {
    return this._isValid;
  }

  set isValid(value) {
    this._isValid = value;
  }

  // Method to get the time as a string
  getIsoTime() {
    return TimeService.convertToTimeString(this.hour, this.minute);
  }

  getHHMMTime() {
    return `${this.hour.toString().padStart(2, "0")}:${this.minute
      .toString()
      .padStart(2, "0")}`;
  }
}

export default HourMinute;
