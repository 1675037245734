import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
//Utils
import { useLanguage } from "../../../../services/LanguageContext";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
//COMPONENTS
import { Heading } from "../../../../utils/utilsstyles";
import Timer from "../../../Forms/Timer";
import { Ikonka } from "../../../Icons/Icon";
import HourMinute from "../../../../services/utils/HourMinute";

//Styles
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 5px;
  }
`;

const ConterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  position: relative;
`;

const ConterButton = styled.div`
  margin: 0 auto;
  margin-top: -5.3rem;
  margin-bottom: 3rem;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  svg {
    width: 50px;
    height: auto;
    fill: ${theme.color.base1};
    transition: all 0.6s ease-in-out;
  }

  &:hover {
    background-color: ${theme.color.base6};
    border-radius: 100%;
    transition: all 0.6s ease-in-out;
    svg {
      fill: ${theme.color.prim1};
      transition: all 0.6s ease-in-out;
    }
  }
`;

const Counter = styled.div`
  //border: 6px solid ${theme.color.base1};
  width: 200px;
  height: 200px;
  border-radius: 100px;
  display: grid;
  grid-template-columns: 150px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  transition: all 0.6s ease-in-out;

  justify-self: center;
  align-self: center;
  margin-top: 2rem;
  box-shadow: 4px 4px 17px 6px rgba(0, 0, 0, 0.1),
    -4px -4px 8px 0 rgba(255, 255, 255, 0.5);

  /* &:hover {
    border: 6px solid ${theme.color.prim1};
    h6 {
      color: ${theme.color.prim1};
      transition: all 0.6s ease-in-out;
    }
    svg {
      fill: ${theme.color.prim1};
      transition: all 0.6s ease-in-out;
    }
  } */

  h6 {
    font-size: 36px;
    font-weight: 500;
    margin: 0;
    margin-top: -2.3rem;
    padding: 0;
    color: ${theme.color.base1};
  }
`;

const Period = styled.div`
  margin-bottom: 1rem;
  p.valid {
    color: red;
    font-weight: 500;
  }
`;

//App
function CountingMode({ device }) {
  const { translate } = useLanguage();
  const [isTimeValid, setIsTimeValid] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [countTime, setCountTime] = useState(new HourMinute(0, 1));

  // Countdown effect
  useEffect(() => {
    let timerId;

    if (isRunning && secondsLeft > 0) {
      timerId = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setIsRunning(false);
    }

    // Clear interval on component unmount or when countdown reaches zero
    return () => clearInterval(timerId);
  }, [isRunning, secondsLeft]);

  useEffect(() => {
    if (device) {
      console.log(device.getStatusValue("countdown_1"));
      setIsRunning(device.getStatusValue("countdown_1") > 0);

      if (Math.abs(device.getStatusValue("countdown_1") - secondsLeft) > 10) {
        setSecondsLeft(device.getStatusValue("countdown_1"));
      }
    }
  }, [device]);

  const timeChangedHandler = (hour, minute, isValid) => {
    setIsTimeValid(isValid);
    setCountTime(new HourMinute(hour, minute));
    setSecondsLeft(hour * 3600 + minute * 60);
  };

  const minTime = new HourMinute(0, 1);
  const maxTime = new HourMinute(11, 59);

  // Start and stop the countdown
  const handleStartCountdown = () => {
    if (!isRunning) {
      device.setOnDeviceByParams(
        "countdown_1",
        countTime._hour * 3600 + countTime._minute * 60
      );
    } else {
      device.setOnDeviceByParams("countdown_1", 0);
    }
    setIsRunning((prev) => !prev);
  };

  // Format time to display as HH:MM:SS
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("relay_counting")}</h3>
      </Heading>

      {!isRunning && (
        <>
          <Timer
            label={translate("relay_start_hour")}
            timeChanged={timeChangedHandler}
            hour={countTime.hour}
            minute={countTime.minute}
            minTime={minTime.getIsoTime()}
            maxTime={maxTime.getIsoTime()}
            initialHourMax={11}
          />
          <Period>
            <p className={isTimeValid ? "" : "valid"}>
              {translate("choose_beetween")}:{" "}
              <b>
                {minTime.getHHMMTime()} - {maxTime.getHHMMTime()}
              </b>
            </p>
          </Period>
        </>
      )}

      {isTimeValid && (
        <ConterWrapper>
          <Counter>
            {/* {isRunning && <h6>{formatTime(secondsLeft)}</h6>} */}
            <h6>{formatTime(secondsLeft)}</h6>
          </Counter>
          <ConterButton onClick={handleStartCountdown}>
            <Ikonka name={isRunning ? "stop" : "play"} />
          </ConterButton>
        </ConterWrapper>
      )}
    </Wrapper>
  );
}

export default CountingMode;
