import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./utils/globalStye";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/theme";
import { AuthProvider } from "./services/AuthContext";
import { LanguageProvider } from "./services/LanguageContext";
import { SignalRProvider } from "./services/SignalRProvider";

// PAGES
import App from "./App";
import Login from "./pages/Login/login";
import Main from "./pages/main";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <AuthProvider>
          <SignalRProvider>
            <GlobalStyle />
            <Main />
          </SignalRProvider>
        </AuthProvider>
      </LanguageProvider>
    </ThemeProvider>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
