import React from "react";
//STYLES
import styled from "styled-components";
import Input from "../Forms/Input";
import { Heading } from "../../utils/utilsstyles";

//DATA
import { useLanguage } from "../../services/LanguageContext";

const ComponentWrapper = styled.div`
  margin-top: 3rem;
  margin-right: -1rem;
`;

const Wrapper = styled.div`
  margin-bottom: 1.3rem;
  display: grid;
  grid-template-columns: 100px 1fr auto;
  row-gap: 2rem;
  column-gap: 1rem;
  align-items: center;
  justify-items: stretch;
  justify-content: stretch;
  p {
    text-transform: capitalize;
  }

  button {
    width: 100%;
    justify-self: stretch;
    align-self: stretch;
    text-align: center;
    justify-content: center;
    padding: 20px;
    line-height: 1;
  }
`;

function DiagnosticDeviceInformation({ device }) {
  const { translate } = useLanguage();

  return (
    <ComponentWrapper>
      <Heading>
        <h3>{translate("device_diagnostic_information")}</h3>
      </Heading>

      {
        // Informacje diagnostyczne - device ID
      }
      <Wrapper>
        <p>ID</p>
        <Input value={device.id} readOnly={true} />
      </Wrapper>

      {
        // Wersje firmware
      }
      {device.firmwareVersions.map((item, index) => (
        <Wrapper key={index}>
          <p>{translate(item.name)}</p>
          <Input value={item.version} readOnly={true} />
        </Wrapper>
      ))}
    </ComponentWrapper>
  );
}

export default DiagnosticDeviceInformation;
