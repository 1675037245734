import React, { useEffect, useState } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../utils/theme";
//COMPONENTS
import { CircularSliderWithChildren } from "react-circular-slider-svg";
import Button from "../../Button/Button";
//FUNCTIONS
import { useLanguage } from "../../../services/LanguageContext";
import DebounceService from "../../../services/DebounceService";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: -2rem;
  margin-top: -1.5rem;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  margin: 0;
  //justify-content: center;
  //justify-items: center;
  align-content: center;
  //padding-bottom: 4.5rem;
  //padding-top: 2rem;
  > div {
    margin-top: 0;
    height: 40px;
  }

  p {
    font-size: 32px;
    font-weight: 500;
    color: ${theme.color.base1};
    text-align: center;
    transform: translate(-35%, -25%);

    &:hover {
      //background-color: transparent;
      color: ${theme.color.prim1};
    }
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 100px;
  width: 140px;
  height: 140px;
  margin-top: -12px;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.1);
  //background-color: ${theme.color.sec7};
  h6 {
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
  }

  p {
    text-align: center;
    font-size: 14px;
    line-height: 1;

    margin-top: -15px;
    margin-bottom: 6px;
  }
`;

function CircSlider({
  temperatureset,
  temperature,
  buttonMinus,
  buttonPluse,
  onChanged,
  onChangedDebounce,
  minTemperature,
  maxTemperature,
  step = 0.5, // Added step prop with default value 1
}) {
  const { translate } = useLanguage();
  const [value1, setValue1] = useState(temperatureset);

  useEffect(() => {
    // Update tempSet when the component mounts
    setValue1(temperatureset);
  }, [temperatureset]); // useEffect dependency on device

  const handleValueChange = (value) => {
    const newValue = parseFloat(
      (
        Math.round((value - temperatureset) / step) * step +
        temperatureset
      ).toFixed(1)
    );
    setValue1(newValue);
    onChanged(newValue);
    DebounceService.getInstance().setValueWithDebounce(
      onChangedDebounce,
      newValue
    );
  };

  return (
    <Wrapper>
      <CircularSliderWithChildren
        handle1={{
          value: value1,
          onChange: handleValueChange,
        }}
        arcColor="#96C11F"
        startAngle={45}
        endAngle={325}
        trackWidth={6}
        size={230}
        minValue={minTemperature}
        maxValue={maxTemperature}
      >
        <ChildWrapper>
          <p>
            {translate("temp")} <br />
            {translate("set")}
          </p>
          <h6>{value1.toFixed(1)}°C</h6>
        </ChildWrapper>
      </CircularSliderWithChildren>
      <ButtonsWrapper>
        <Button
          variant="whitePlusMinus"
          onClick={() => handleValueChange(value1 + step)}
        >
          <p>+</p>
        </Button>
        <Button
          variant="whitePlusMinus"
          onClick={() => handleValueChange(value1 - step)}
        >
          <p>-</p>
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
}

export default CircSlider;
