import React, { useState } from "react";
//STYLES
import styled from "styled-components";

//DATA
//COMPONENTS
import { FullCardWrapper, Heading } from "../../../utils/utilsstyles";
import ButtonRounded from "../../Button/ButtonRounded";
import HarmonogramMode from "./WorkingModes/HarmonogramMode";
import RandomMode from "./WorkingModes/RandomMode";
import AutoOffMode from "./WorkingModes/AutoOffMode";
import PeriodMode from "./WorkingModes/PeriodMode";
import CountingMode from "./WorkingModes/CountingMode";
//FUNCTIONS
import { useLanguage } from "../../../services/LanguageContext";

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Line = styled.hr`
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
`;

function RelayWorkingMode({ device }) {
  const mode_counting = "Counting";
  const mode_program = "Program";
  const mode_period = "Period";
  const mode_random = "Random";
  const mode_auto_off = "AutoOff";
  const { translate } = useLanguage();

  const [activeMode, setActiveMode] = useState(
    localStorage.getItem("relayActiveMode") ?? mode_counting
  );

  const updateActiveMode = (activeMode) => {
    localStorage.setItem("relayActiveMode", activeMode);
    setActiveMode(activeMode);
  };

  return (
    <FullCardWrapper>
      <Heading>
        <h3>{translate("working_mode")}</h3>
        <p>{translate("choose_working_mode")}</p>
      </Heading>
      <ButtonsWrapper>
        <ButtonRounded
          label={translate("counting_mode")}
          icon="counting"
          active={activeMode === mode_counting}
          onClick={() => updateActiveMode(mode_counting)}
        />
        <ButtonRounded
          label="Harmonogram"
          icon="harmonogram"
          active={activeMode === mode_program}
          onClick={() => updateActiveMode(mode_program)}
        />
        <ButtonRounded
          label={translate("period_mode")}
          icon="time"
          active={activeMode === mode_period}
          onClick={() => updateActiveMode(mode_period)}
        />
        <ButtonRounded
          label={translate("random_mode")}
          icon="random"
          active={activeMode === mode_random}
          onClick={() => updateActiveMode(mode_random)}
        />
        <ButtonRounded
          label={translate("auto_off")}
          icon="power"
          active={activeMode === mode_auto_off}
          onClick={() => updateActiveMode(mode_auto_off)}
        />
      </ButtonsWrapper>

      <Line />

      {activeMode === mode_counting && <CountingMode device={device} />}
      {activeMode === mode_program && <HarmonogramMode device={device} />}
      {activeMode === mode_period && <PeriodMode device={device} />}
      {activeMode === mode_random && <RandomMode device={device} />}
      {activeMode === mode_auto_off && <AutoOffMode device={device} />}
    </FullCardWrapper>
  );
}

export default RelayWorkingMode;
