import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  justify-content: center;

  &.width400 {
    width: 400px;
  }
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  color: ${theme.color.sec5};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Inp = styled.input`
  margin: 0;
  padding: 10px 10px;
  border-radius: 8px;
  border: 1px solid lightgray;
  font-size: 16px;
  font-weight: 300;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 36px;
  outline: 0;
  width: 100%;
  background-color: ${theme.color.sec9};
  color: ${theme.color.sec1};
  box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2);

  &:autofill {
    box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2);
    color: ${theme.color.sec3};
  }

  &:-webkit-autofill {
    box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2);
    color: ${theme.color.sec3};
  }

  &.white {
    background-color: white;
    box-shadow: inset 0px 0px 6px 1px rgba(66, 68, 90, 0.2);
  }
`;

const Input = ({
  label_text,
  label_for,
  type,
  value,
  id,
  name,
  maxLength,
  mb,
  mt,
  onChange,
  className,
  readOnly,
}) => (
  <InputWrapper mt={mt} mb={mb} className={className}>
    <Label htmlFor={label_for}>{label_text}</Label>
    <Inp
      className={className}
      type={type}
      value={value}
      id={id}
      name={name}
      maxLength={maxLength}
      onChange={onChange}
      readOnly={readOnly}
    />
  </InputWrapper>
);

export default Input;
