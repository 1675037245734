import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";

//SERVICES
import { useLanguage } from "../../services/LanguageContext";
// import SignalRService from "../../services/SignalRService";
import { useSignalR } from "../../services/SignalRProvider";
import { loadDeviceDetails } from "../../services/ApiService";
import Device from "../../services/Device";

//COMPONENTS
import RouteLayout from "../../layout/RouteLayout";
import TrybPracy from "./Relay/RelayWorkingMode";
import { FullCardWrapper, Heading, Loader } from "../../utils/utilsstyles";
import { Ikonka } from "../Icons/Icon";
import CircIconLabeled from "../Icons/CircIconLabeled";
import PrimaryDeviceSettings from "./PrimaryDeviceSettings";
import DiagnosticDeviceInformation from "./DiagnosticDeviceInformation";
import Dropdown from "../Forms/Dropdown";
import Input from "../Forms/Input";
import CircIcon from "../Icons/CircIcon";

const Wrapper = styled.div`
  z-index: 10;
  background-color: ${theme.color.secondLight};
`;

const AllSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

const CurrentSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 6rem;
  row-gap: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;

  > div {
    display: grid;
    grid-template-columns: 2fr 3fr;

    > div:nth-child(2) {
      min-width: 200px;
      justify-self: end;

      > div {
        min-width: 200px;
      }
    }
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-bottom: 2rem;
  margin-top: 1.5rem;

  button {
    align-self: center;
    background-color: transparent;
    padding: 0;
    width: 100px;
    transition: all 0.6s ease-in-out;
    margin-top: -1rem;

    svg {
      fill: ${theme.color.base1};
      width: 100px;
      height: 100px;
      transition: all 0.6s ease-in-out;
    }

    h6 {
      color: ${theme.color.base1};
      text-transform: uppercase;
      transition: all 0.6s ease-in-out;
    }

    &:hover {
      background-color: transparent;

      svg {
        fill: ${theme.color.prim1};
        background-color: transparent;
      }

      h6 {
        color: ${theme.color.prim1};
        background-color: transparent;
      }

      div {
        background-color: transparent;
      }
    }
  }
`;

const TemperatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  p,
  h5 {
    text-align: center;
  }

  h5 {
    font-weight: 500;
    color: ${theme.color.base1};
  }
`;

const OneSetting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const OnOffButton = styled.div`
  cursor: pointer;

  > div {
    width: 100px;
    height: 100px;
    svg {
      width: 60px;
      max-width: 60px;
      height: 60px;
      max-height: 60px;
    }

    &:hover {
      background-color: ${theme.color.primary1};
    }
  }

  &.off {
    h6 {
      color: ${theme.color.second};
    }
  }
`;

function RelaySettings({ onClick, title, type }) {
  const { id } = useParams(); // Get the id from the URL
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);

  const { translate } = useLanguage();
  const signalRService = useSignalR();

  // Fetch the initial device details
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      const fetchedDeviceDetails = await loadDeviceDetails(id); // Use the id from the URL
      console.log("Relay details", fetchedDeviceDetails);
      setDevice(new Device(fetchedDeviceDetails));
      setLoading(false);
    };

    fetchDeviceDetails();
  }, [id]);

  // Register to SignalRService when the component mounts and unregister when it unmounts
  useEffect(() => {
    const handleUpdateDeviceStatus = (updatedDevice) => {
      // console.log("Update recieved from SignalR");

      if (updatedDevice.id === id) {
        console.log("Update recieved from SignalR");
        console.log(updatedDevice);
        setDevice(new Device(updatedDevice));
      }
    };

    signalRService.subscribe("UpdateDeviceStatus", handleUpdateDeviceStatus);

    return () => {
      signalRService.unsubscribe(
        "UpdateDeviceStatus",
        handleUpdateDeviceStatus
      );
    };
  }, [id]);

  if (loading) {
    return (
      <Loader>
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color={theme.color.base1}
          secondaryColor={theme.color.base1}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Loader>
    ); // Or any other loading indicator
  }

  const switch_type = "switch_type";

  return (
    <Wrapper>
      <RouteLayout
        onClick="/"
        title={device.name}
        type={translate("deviceRelay")}
      />

      <AllSettingsWrapper>
        <TrybPracy device={device}></TrybPracy>
        <FullCardWrapper>
          <Heading>
            <h3>{translate("power_supply")}</h3>
          </Heading>
          <SettingsWrapper>
            {
              // BUtton ON/OFF
            }
            <button>
              {device.getStatusValue("switch_1") ? (
                <OnOffButton
                  onClick={() => {
                    device.setOnDeviceByParams("switch_1", false);
                  }}
                >
                  <CircIcon name="power" />
                  <h6>on</h6>
                </OnOffButton>
              ) : (
                <OnOffButton
                  className="off"
                  onClick={() => {
                    device.setOnDeviceByParams("switch_1", true);
                  }}
                >
                  <CircIcon name="power" className="off" />
                  <h6>off</h6>
                </OnOffButton>
              )}
            </button>
          </SettingsWrapper>

          {
            // Temperatura
          }
          {device.checkIfPropertyExists(switch_type) &&
            device.getSelectedEnum(switch_type, translate).value ==
              "external_ntc" && (
              <TemperatureWrapper>
                <p>{translate("temperature")}</p>
                <h5>{device.getStatusValue("external_ntc_temp") / 10} °C</h5>
              </TemperatureWrapper>
            )}

          <Heading>
            <h3>{translate("current_settings")}</h3>
          </Heading>
          <CurrentSettingsWrapper>
            {
              //Status przekaźnika
            }
            {device.checkIfPropertyExists("relay_status") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("relay_status")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues("relay_status", translate)}
                  defaultValue={device.getSelectedEnum(
                    "relay_status",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "relay_status",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )}

            {
              //Typ przełącznika
            }
            {device.checkIfPropertyExists("switch_type") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("relay_switch_type")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues("switch_type", translate)}
                  defaultValue={device.getSelectedEnum(
                    "switch_type",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "switch_type",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )}

            {
              //Stan w trybie Offline
            }
            {device.checkIfPropertyExists("offline_processing") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("relay_offline_processing")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues(
                    "offline_processing",
                    translate
                  )}
                  defaultValue={device.getSelectedEnum(
                    "offline_processing",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "offline_processing",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )}

            {
              //Status S1/N
            }
            {device.checkIfPropertyExists("input_state") &&
              device.checkIfPropertyExists("switch_type") &&
              device.getSelectedEnum("switch_type", translate).value !==
                "external_ntc" && (
                <OneSetting>
                  <CircIconLabeled
                    name="settings"
                    label={translate("relay_input_state")}
                  />
                  <Input
                    value={
                      device.getSelectedEnum("input_state", translate)?.label ||
                      ""
                    }
                    readOnly={true}
                  />
                </OneSetting>
              )}
          </CurrentSettingsWrapper>
          <PrimaryDeviceSettings device={device} name={device.name} />
          <DiagnosticDeviceInformation device={device} />
        </FullCardWrapper>
      </AllSettingsWrapper>
    </Wrapper>
  );
}

export default RelaySettings;
