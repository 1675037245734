import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../../utils/theme";
//DATA
import { week } from "../../../../../data/data";
import { Ikonka } from "../../../../Icons/Icon";
import CircIcon from "../../../../Icons/CircIcon";
import { useLanguage } from "../../../../../services/LanguageContext";
//COMPONENTS
import Button from "../../../../Button/Button";
import ButtonRounded from "../../../../Button/ButtonRounded";
import Modal from "../../../../Modals/Modal";
import Slider from "../../../../Slider/Slider";
import LeftMenuSettings from "../../../LeftMenuSettings";
import Input from "../../../../Forms/Input";
import TimePicker from "react-time-picker";
import Timer from "../../../../Forms/Timer";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

const Line = styled.hr`
  margin-top: 0.7rem !important;
  margin-bottom: 0.7rem !important;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 36px;
  column-gap: 1rem;
  button {
    background: transparent;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 14px;
  }

  p:first-of-type {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 4px;
  }
`;

const Period = styled.div`
  margin-bottom: 1rem;
  p.valid {
    color: red;
    font-weight: 500;
  }
`;

function HarmonogramSettings({
  name,
  device,
  icon,
  start_hour,
  start_hour_hour,
  start_hour_minute,
  temp_set,
  openModal,
  onSegmentChange,
  minTime,
  maxTime,
  initialSegment, // change from segment to initialSegment to avoid confusion
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [segment, setSegment] = useState(null); // Set segment in state
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [temperature, setTemperature] = useState(null);
  const [isTimeValid, setIsTimeValid] = useState(true);

  // Effect to update local segment state when initialSegment changes
  useEffect(() => {
    if (initialSegment !== null) {
      setSegment(initialSegment);
      setHour(initialSegment.hour);
      setMinute(initialSegment.minute);
      setTemperature(initialSegment.temperature);
    }
  }, [initialSegment]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { translate } = useLanguage();
  const program_temp = "program_temp";

  const handleConfirm = () => {
    segment.hour = hour;
    segment.minute = minute;
    segment.temperature = temperature;

    toggleModal();

    onSegmentChange(segment);
  };

  const timeChangedHanlder = (hour, minute, isValid) => {
    setHour(hour);
    setMinute(minute);
    setIsTimeValid(isValid);
  };

  const formatTimeString = (isoString) => {
    const date = new Date(isoString); // Convert ISO string back to Date
    const hours = String(date.getHours()).padStart(2, "0"); // Get hours and pad with zero if needed
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Get minutes and pad with zero if needed
    return `${hours}:${minutes}`; // Return formatted time
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title={translate("settings")}
          onClose={toggleModal}
          onConfirm={handleConfirm}
          isValid={isTimeValid}
        >
          <Timer
            hour={segment.hour}
            minute={segment.minute}
            minTime={minTime}
            maxTime={maxTime}
            timeChanged={timeChangedHanlder}
          />
          <Period>
            <p className={isTimeValid ? "" : "valid"}>
              {translate("choose_beetween")}:{" "}
              <b>
                {formatTimeString(minTime)} - {formatTimeString(maxTime)}
              </b>
            </p>
          </Period>

          <Slider
            icon="temperature"
            label={translate("temperature")}
            value={device.convertToTempScale(segment.temperature)}
            min={device.getFromRangeInTempScale(program_temp)}
            max={device.getToRangeInTempScale(program_temp)}
            step={device.getStepRangeInTempScale(program_temp)}
            onChange={(value) => {
              setTemperature(device.convertBackFromScaledValue(value));
            }}
          />
        </Modal>
      )}
      <Wrapper>
        <CircIcon name={icon} />
        <InfoWrapper>
          <p>{name}</p>
          <p>
            {start_hour_hour}
            <sup>
              {start_hour_minute < 10 ? "0" : ""}
              {start_hour_minute}
            </sup>{" "}
            | {temp_set}°C
          </p>
        </InfoWrapper>

        <ButtonRounded icon="settings" onClick={toggleModal} />
      </Wrapper>
      <Line />
    </>
  );
}

export default HarmonogramSettings;
