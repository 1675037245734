import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Call the async function
    checkUserIsLoggedIn();

    window.addEventListener("unauthorized", logout);

    return () => {
      window.removeEventListener("unauthorized", logout);
    };
  }, []);

  const checkUserIsLoggedIn = async () => {
    try {
      // Assume fetchUrl returns a promise
      const token = await Cookies.get("token");
      setIsLoggedIn(!!token); // Set to true if token is not null
      return !!token;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setIsLoggedIn(false); // Set to true if token is not null
      return false;
    }
  };

  const login = async (token) => {
    // tutaj logika logowania
    Cookies.set("token", token, { expires: 2, secure: true });
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    Cookies.remove("token");
    setIsLoggedIn(false);
    // Perform any other logout operations here
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, checkUserIsLoggedIn }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
