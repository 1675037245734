import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { breakpoint } from "styled-components-breakpoint";
//DATA
import power from "./../../assets/icons/power.png";
import powerOff from "./../../assets/icons/powerOff.png";
//COMPONENTS
import Input from "../../components/Forms/Input";
import { Label } from "../../utils/typo";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${theme.color.base1};
    &.off {
      color: ${theme.color.sec1};
    }
  }
`;

const Button = styled.button`
  justify-self: center;
  align-self: center;
  background-color: transparent;
  border: 2px solid ${theme.color.base1};
  padding: 0;
  width: 70px;
  height: 70px;
  border-radius: 40px;
  margin: 0.2rem auto;
  transition: all 0.4s ease-in-out;

  img {
    width: 36px;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    background-color: ${theme.color.sec6};

    img {
      background-color: transparent;
      opacity: 0.5;
    }
  }

  &.off {
    border: 2px solid ${theme.color.sec1};
  }
`;

function OnOffButton({ onClick, status }) {
  return (
    <Wrapper>
      <Button onClick={onClick} status={status} className={status ? "" : "off"}>
        <img src={status ? power : powerOff} />
      </Button>
      <Label className={status ? "" : "off"}>{status ? "on" : "off"}</Label>
    </Wrapper>
  );
}

export default OnOffButton;
