import { color } from "./color";

export const theme = {
  color,

  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "980px",
    lg: "1320px",
    xl: "1921px",
  },

  font: {
    main: "Segoe UI",
    header: "Ubuntu",
    special: "Ubuntu",

    weight: 300,
    weightBold: 700,
    weightH: 700,

    sizeSmall: "14px",

    size: {
      p: {
        xs: "16px",
        s: "16px",
        m: "16px",
        l: "16px",
        xl: "16px",
      },

      l: {
        xs: "13px",
        s: "14px",
        m: "15px",
        l: "16px",
        xl: "17px",
      },

      h1: {
        xs: "32px",
        s: "42px",
        m: "44px",
        l: "46px",
        xl: "48px",
      },

      h2: {
        xs: "32px",
        s: "34px",
        m: "36px",
        l: "38px",
        xl: "40px",
      },

      h3: {
        xs: "24px",
        s: "26px",
        m: "28px",
        l: "30px",
        xl: "32px",
      },

      h4: {
        xs: "18px",
        s: "23px",
        m: "24px",
        l: "25px",
        xl: "26px",
      },

      h5: {
        xs: "19px",
        s: "20px",
        m: "21px",
        l: "22px",
        xl: "23px",
      },

      h6: {
        xs: "17px",
        s: "18px",
        m: "19px",
        l: "20px",
        xl: "21px",
      },
    },

    lineH: {
      p: {
        xs: "1.2",
        s: "1.2",
        m: "1.3",
        l: "1.3",
        xl: "1.4",
      },

      l: {
        xs: "1.1",
        s: "1.1",
        m: "1.1",
        l: "1.1",
        xl: "1.1",
      },

      h1: {
        xs: "1.2",
        s: "1.2",
        m: "1.2",
        l: "1.2",
        xl: "1.2",
      },

      h2: {
        xs: "1.3",
        s: "1.3",
        m: "1.3",
        l: "1.3",
        xl: "1.3",
      },

      h3: {
        xs: "1.2",
        s: "1.3",
        m: "1.3",
        l: "1.3",
        xl: "1.3",
      },

      h4: {
        xs: "1.2",
        s: "1.3",
        m: "1.3",
        l: "1.3",
        xl: "1.3",
      },

      h5: {
        xs: "1.1",
        s: "1.1",
        m: "1.1",
        l: "1.1",
        xl: "1.1",
      },

      h6: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },
    },
  },
};
