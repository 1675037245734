import React from "react";
import styled, { css } from "styled-components";

const variantOptions = {
  base: {
    color_font: "white",
    color_font_hover: "white",
    color: (props) => props.theme.color.base1,
    color_hover: (props) => props.theme.color.prim1,
    border: "none",
  },
  white_frame: {
    color_font: "white",
    color_font_hover: (props) => props.theme.color.prim1,
    color: "transparent",
    color_hover: "white",
    border: "1px solid white",
  },
  white: {
    color_font: (props) => props.theme.color.base1,
    color_font_hover: (props) => props.theme.color.prim1,
    color: "transparent",
    border: "2px solid white",
    border_color: (props) => props.theme.color.base1,
    border_color_hover: (props) => props.theme.color.prim1,
  },
  whitePlusMinus: {
    color_font: (props) => props.theme.color.base1,
    color_font_hover: (props) => props.theme.color.prim1,
    color: "transparent",
    border: "2px solid white",
    border_color: (props) => props.theme.color.base1,
    border_color_hover: (props) => props.theme.color.prim1,
    width: "40px",
    padding: "1rem",
  },
  arrow: {
    color_font: "red",
    color: (props) => props.theme.color.sec1,
    border: "1px solid white",
    padding: "1rem 1rem",
  },
};

const ButtonWrapper = styled.div`
  display: flex;
  //width: 100%;
  justify-content: center;
  align-content: center;
  //padding-top: ${(props) => props.mt || "2rem"};
  //padding-bottom: ${(props) => props.mb || "2rem"};
`;

const ButtonAll = `
    align-content: center;
    align-items: center;
    border-radius: 8px;
    height: 40px;
    justify-self: center;
    align-self: center;
    display: flex;
    position: relative;
    text-decoration: none;
    padding-left: 3rem;
    padding-right: 3rem;
    transition: all 0.4s ease-in-out;
    line-height:1.1;
    font-weight: 600;
    font-size: 18px;
    //text-transform: capitalize;
    //color: red !important;

    

    p {
      transition: all 0.4s ease-in-out;
    }

    b {
        color: transparent;
      }
    
      :focus {
        border: 1px solid #4f6335;
      }
      

`;
const BtnA = styled.a`
  ${ButtonAll}
  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      color: ${variantOptions[variant].color_font};
      background-color: ${variantOptions[variant].color};
      border: ${variantOptions[variant].border};
      border-color: ${variantOptions[variant].border_color};
      height: ${variantOptions[variant].width};
      padding: ${variantOptions[variant].padding};
      line-height: ${variantOptions[variant].line_height};

      &:hover {
        color: ${variantOptions[variant].color_font_hover};
        background-color: ${variantOptions[variant].color_hover};
        border-color: ${variantOptions[variant].border_color_hover};
      }
    `}
`;

const BtnB = styled.button`
  ${ButtonAll}
  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      color: ${variantOptions[variant].color_font};
      background-color: ${variantOptions[variant].color};
      border: ${variantOptions[variant].border};
      border-color: ${variantOptions[variant].border_color};
      height: ${variantOptions[variant].width};
      padding: ${variantOptions[variant].padding};
      line-height: ${variantOptions[variant].line_height};
      width: ${variantOptions[variant].width};

      &.loading {
        display: flex;
        color: transparent;
        background-color: lightgrey;
        cursor: auto;

        &:hover {
          display: flex;
          color: transparent;
          background-color: lightgrey;
        }

        &:after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 100%;
          border: 3px solid rgba(255, 255, 255, 0.5);
          border-left-color: #fff;
          border-top-color: #fff;
          animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }

        @keyframes spin {
          to {
            transform: rotate(359deg);
          }
        }
        @keyframes grow {
          to {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            right: 0px;
          }
        }
      }

      &:hover {
        color: ${variantOptions[variant].color_font_hover};
        background-color: ${variantOptions[variant].color_hover};
        border-color: ${variantOptions[variant].border_color_hover};

        p {
          background-color: transparent;
        }
      }
    `}
`;

const Button = ({
  children,
  variant,
  href,
  target,
  state,
  onClick,
  className,
  mb,
  mt,
}) => (
  <ButtonWrapper mt={mt} mb={mb}>
    {href ? (
      <BtnA
        variant={variant || "base"}
        href={href}
        target={target}
        className={className}
      >
        {children}
      </BtnA>
    ) : (
      <BtnB
        variant={variant || "base"}
        onClick={onClick}
        state={state}
        className={className}
      >
        {children}
      </BtnB>
    )}
  </ButtonWrapper>
);

export default Button;
