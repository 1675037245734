import React from "react";
//DATA
import termostat from "../../assets/listwa.png";
//COMPONENTS
import CardWrapper from "./CardTypes/CardElements/CardWrapper";
import CardHeader from "./CardTypes/CardElements/CardHeader";
import InfoLine from "./CardTypes/CardElements/InfoLine";
import CardFastSettingsWrapper from "./CardTypes/CardElements/CardFastSettingsWrapper";

function Card({ name, rooms, deviceId, type, typeId, device, isOnline }) {
  return (
    <CardWrapper
      settings={<CardFastSettingsWrapper typ={type}></CardFastSettingsWrapper>}
    >
      <CardHeader
        name={name}
        type={type}
        deviceId={deviceId}
        foto={termostat}
        device={device}
        isOnline={isOnline}
        typeId={typeId}
        onCliClick="openSettings"
      />
      <InfoLine room={rooms} OnClick="" isOnline={isOnline} />
    </CardWrapper>
  );
}

export default Card;
