import React from "react";
//STYLES
import styled from "styled-components";
import CircIconLabeled from "../../components/Icons/CircIconLabeled";
import RouteLayout from "../../layout/RouteLayout";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5px;
  justify-items: stretch;
  align-items: center;
`;

const Messages = ({ title }) => {
  return (
    <RouteLayout title={title}>
      <Wrapper>
        <CircIconLabeled name="home" value="home" />
        <CircIconLabeled name="user" value="user" />
        <CircIconLabeled name="temperature" value="temperature" />
        <CircIconLabeled name="temperatureset" value="temperatureset" />
        <CircIconLabeled name="messages" value="messages" />
        <CircIconLabeled name="overview" value="overview" />
        <CircIconLabeled name="logout" value="logout" />
        <CircIconLabeled name="more" value="more" />
        <CircIconLabeled name="powerbutton" value="powerbutton" />
        <CircIconLabeled name="down" value="down" />
        <CircIconLabeled name="up" value="up" />
        <CircIconLabeled name="engo" value="engo" />
        <CircIconLabeled name="harmonogram" value="harmonogram" />
        <CircIconLabeled name="holiday" value="holiday" />
        <CircIconLabeled name="status" value="status" />
        <CircIconLabeled name="workingmode" value="workingmode" />
        <CircIconLabeled name="hydro" value="hydro" />
        <CircIconLabeled name="block" value="block" />
        <CircIconLabeled name="light" value="light" />
        <CircIconLabeled name="light2" value="light2" />
        <CircIconLabeled name="tempmax" value="tempmax" />
        <CircIconLabeled name="tempmin" value="tempmin" />
        <CircIconLabeled name="choose" value="choose" />
        <CircIconLabeled name="morning1" value="morning1" />
        <CircIconLabeled name="morning2" value="morning2" />
        <CircIconLabeled name="noon" value="noon" />
        <CircIconLabeled name="afternoon" value="afternoon" />
        <CircIconLabeled name="evening" value="evening" />
        <CircIconLabeled name="night" value="night" />
        <CircIconLabeled name="settings" value="settings" />
        <CircIconLabeled name="manual" value="manual" />
        <CircIconLabeled name="frost" value="frost" />
        <CircIconLabeled name="wire" value="wire" />
        <CircIconLabeled name="wireless" value="wireless" />
        <CircIconLabeled name="time" value="time" />
      </Wrapper>
    </RouteLayout>
  );
};

export default Messages;
