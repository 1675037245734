import React from "react";
// STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";

// SERVICES
import { useLanguage } from "../../../../services/LanguageContext";

// COMPONENTS
import { Ikonka } from "../../../Icons/Icon";
import { Tag } from "../../../../utils/utilsstyles";
import CircIconLabeled from "../../../Icons/CircIconLabeled";

const Infoline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  //background-color: red;
  height: 85px;

  > div {
    display: flex;
  }

  svg {
    fill: ${theme.color.base1};
    height: 40px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      fill: ${theme.color.prim1};
    }
  }

  a.off {
    svg {
      fill: ${theme.color.sec5};

      &:hover {
        fill: ${theme.color.prim1};
      }
    }
  }
`;

const TempGrid = styled.div`
  display: grid;
  column-gap: 1.9rem;

  &.height0 {
    height: 0;
  }

  &.margintop {
    margin-top: 0.5rem;
  }
`;

const Info = styled.div`
  display: flex;

  //background-color: antiquewhite;

  &.marginleft {
    margin-left: 13px;
  }

  svg {
    align-self: center;
    fill: ${theme.color.sec1};
    height: 24px;
  }

  p {
    margin-left: 0.2rem;
    font-size: 24px;
    font-weight: 500;
    align-self: center;
    transform: translateY(-1px);
  }
`;

function InfoLine({
  device,
  temperatureset,
  temperature,
  onClick,
  status,
  rooms,
  isOnline,
}) {
  const { translate } = useLanguage();

  return (
    <Infoline>
      {isOnline && (
        <>
          <TempGrid className={rooms && rooms.length >= 1 ? "" : "margintop"}>
            {rooms &&
              rooms.length >= 1 &&
              rooms.map((room) => (
                <Tag key={room.name}>
                  <p>{room.name}</p>
                </Tag>
              ))}
            {temperatureset && (
              <Info className={rooms && rooms.length >= 1 ? "" : "marginleft"}>
                <Ikonka name="temperatureset" />
                <p>{temperatureset}°C</p>
              </Info>
            )}
            {temperature && (
              <Info>
                <Ikonka name="temperature" />
                <p>{temperature}°C</p>
              </Info>
            )}
          </TempGrid>
          {/* {temperature && (
            <CircIconLabeled name="flame" label="status urządzenia" />
          )} */}
          {device && device.checkIfPropertyExists("work_state") && (
            <CircIconLabeled
              name={
                device.getWorkModeIconData(
                  device.getStatusValue("work_state")
                )[0]
              }
              className={
                device.getWorkModeIconData(
                  device.getStatusValue("work_state")
                )[1]
              } // For the 'className' prop
              label={translate("thermostatStatus", "Status")}
              value={translate(
                "work_state_" + device.getStatusValue("work_state")
              )}
            />
          )}
        </>
      )}
    </Infoline>
  );
}

export default InfoLine;
