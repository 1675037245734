import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import styled from "styled-components";
import { theme } from "../../utils/theme"; // Ensure the path is correct

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderColor: theme.color.base1,
    borderRadius: "8px",
    boxShadow: "inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2)",
    "&:hover": {
      borderColor: theme.color.base1,
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#9ECD20" // Background color for selected option
      : isFocused
      ? "#DFF2BF" // Background color when hovering over an option
      : undefined,
    color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    cursor: isDisabled ? "not-allowed" : "default",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled && (isSelected ? "#9ECD20" : "#DFF2BF"),
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "black",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: theme.color.base1,
    borderRadius: "4px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
    fontWeight: "600",
    textTransform: "uppercase",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: theme.color.sec5,
      color: "white",
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 1001, // Make sure it appears above the modal
  }),
  menuPortal: (base) => ({ ...base, zIndex: 1001 }),
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.width400 {
    width: 400px;
  }

  &.width100 {
    min-width: 100px;
  }
  &.minWidth {
    max-width: 150px;
    min-width: 30px;
    align-items: flex-end;

    > div {
      width: auto;
    }
  }
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  color: ${theme.color.sec5};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Seelect = styled(Select)`
  width: 100%;
`;

const Dropdown = ({
  label,
  label_for,
  label_text,
  dropdown,
  isMulti,
  handleChange,
  placeholder,
  isClearable,
  handleClear,
  className,
  defaultValue,
  readOnly,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const selectRef = useRef(null);

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    if (handleChange) {
      handleChange(selected);
    }
  };

  const clearSelection = () => {
    if (selectRef.current) {
      selectRef.current.select.clearValue();
      setSelectedOption(null);
    }
  };

  useEffect(() => {
    if (handleClear) {
      handleClear(clearSelection);
    }
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  return (
    <Wrapper className={className}>
      {label_text ? (
        label === false ? (
          ""
        ) : (
          <Label htmlFor={label_for}>{label_text}</Label>
        )
      ) : (
        ""
      )}
      <Seelect
        ref={selectRef}
        value={selectedOption}
        isClearable={isClearable}
        isSearchable
        name="color"
        options={dropdown}
        styles={colourStyles}
        isDisabled={readOnly}
        isMulti={isMulti}
        onChange={handleSelectChange}
        placeholder={placeholder}
        menuPortalTarget={document.body} // Ensure the dropdown menu is rendered in the body
        menuPosition="absolute" // Make sure the menu is positioned correctly
      />
    </Wrapper>
  );
};

export default Dropdown;
