import styled, { css } from "styled-components";
import { breakpoint } from "styled-components-breakpoint";
import { theme } from "../utils/theme";

export const Label = styled.p`
  color: red;
  font-weight: 700;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border-radius: 8px;
  padding: 0.3rem 0.6rem;
  //height: 28px;
  background-color: ${theme.color.base1};
  p {
    color: white;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    text-align: center;
    //transform: translateY(1px);
  }
`;

export const FullCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  justify-items: start;
  background-color: white;
  align-self: flex-start;
  //height: 100%;
  width: 100%;
  padding: 2rem;
  background-color: ${theme.color.sec9};
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1),
    -4px -4px 8px 0 rgba(255, 255, 255, 0.5);
`;

export const Heading = styled.div`
  h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
  }
  margin-bottom: 0.5rem;

  hr {
    margin-bottom: 1.3rem !important;
  }
`;

export const ModeWrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-bottom: 3rem;
  top: 20px;
  bottom: 50px;
  height: calc(100vh - 530px);
`;

export const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Loader100 = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Break = styled.div`
  height: ${(props) => props.height || "1rem"};
`;
